import * as React from 'react'
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton"
import Collapse from "@mui/material/Collapse"
import AddIcon from '@mui/icons-material/AddRounded'
import RemoveRounded from '@mui/icons-material/RemoveRounded'
import FaqItemInterface from "../../types/FaqItem/FaqItemInterface"
import { Box } from "@mui/material"
import Typography from "@mui/material/Typography"

interface FaqItemCardProps {
    faqItem: FaqItemInterface,
}

export default function FaqItemCard(props: FaqItemCardProps): JSX.Element {
    const [open, setOpen] = React.useState(false)
    return (
        <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none'}}>
            <CardContent className='grey-background'
                         style={{borderRadius: '135px 120px 171px 171px / 130px 150px 150px 140px'}}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                        <Typography variant="h6">
                            {props.faqItem.question}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton
                            onClick={() => setOpen(!open)}
                            style={{marginRight: '10px', fontSize: '18px'}}
                            aria-label="expand"
                            size="small"
                        >
                            {open ? (
                                <RemoveRounded style={{color: '#DADADA', fontSize: 32}}/>
                            ) : (
                                <AddIcon style={{color: '#DADADA', fontSize: 30}}/>
                            )}
                        </IconButton>
                    </Box>
                </Box>
            </CardContent>
            <div style={{backgroundColor: '#FFFFFF', color: '#808080'}}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <CardContent style={{
                        border: '1px solid #E8E8E8',
                        borderRadius: '31px 38px 55px 37px / 47px 36px 45px 49px',
                        marginTop: '15px'
                    }}>
                        <Container sx={{height: 'auto', lineHeight: 2}}>
                            {props.faqItem.answer}
                        </Container>
                    </CardContent>
                </Collapse>
            </div>
        </Card>
    )
}
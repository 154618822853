import * as React from 'react'
import { Action, ClientContext } from "react-fetching-library"
import { AuthContext } from "../../App"
import { toast } from "react-toastify"
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import AuthContextType from "../../types/AuthContext/AuthContextType"
import { Box, Card, CardContent, Container, Grid, Tooltip, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from "@mui/icons-material"
import Loading from "../../components/loader/Loading"


export default function Login(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const SOME_FIELDS_ARE_EMPTY: string = 'Niektoré polia sú prázdne!'
    const USER_SUCCESSFULLY_LOGGED_IN: string = 'Používateľ bol úspešne prihlásený!'
    const VERIFY_YOU_ARE_NOT_A_ROBOT: string = 'Overte prosím, že nie ste robot'

    // context
    const clientContext = React.useContext(ClientContext)
    const authContext: AuthContextType = React.useContext(AuthContext)
    // state
    const [username, setUsername] = React.useState<string | null>(null)
    const [password, setPassword] = React.useState<string | null>(null)
    const [isUsernameValid, setIsUsernameValid] = React.useState<boolean>(true)
    const [isPasswordValid, setIsPasswordValid] = React.useState<boolean>(true)
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(true) // defaultne sa nacitava, effect vypne loading, ked context rozhodne ci je user logged alebo nie
    const [passwordVisible, setPasswordVisible] = React.useState(false)
    //- recaptcha
    const IS_RECAPTCHA_ENABLED: boolean = process.env.REACT_APP_IS_RECAPTCHA_ENABLED === 'true'
    const {executeRecaptcha} = useGoogleReCaptcha()
    // init navigate
    const navigate = useNavigate()
    const location = useLocation()
    const locationTo = location?.state?.from || '/'
    // actions
    const loginAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/login_check'),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        body: {
            username: username,
            password: password,
        }
    }

    const verifyRecaptchaAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/recaptcha/verify'),
        headers: {
            Accept: 'application/json'
        }
    }

    // validacie
    const validateUsername = (username: string | null): void => {
        setIsUsernameValid(username !== null)
    }

    const validatePassword = (password: string | null): void => {
        setIsPasswordValid(password !== null)
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }

    // onSubmit
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsLoading(true)
        if (username === null || password === null) {
            validatePassword(password)
            validateUsername(username)
            toast.error(SOME_FIELDS_ARE_EMPTY)
            setIsLoading(false)
            return
        }
        setIsLoading(true)
        const recaptchaToken = await executeRecaptcha?.('login')

        if (recaptchaToken || !IS_RECAPTCHA_ENABLED) {
            //- verify recaptcha
            const verifyRecaptchaAction2: Action = {
                ...verifyRecaptchaAction,
                body: {
                    recaptchaToken: recaptchaToken,
                }
            }
            let canSubmitForm: boolean = false
            if (IS_RECAPTCHA_ENABLED) {
                const {error, status} = await request(verifyRecaptchaAction2, clientContext)
                if (error) {
                    toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
                }
                canSubmitForm = status === 201 || status === 200
            } else {
                canSubmitForm = true
            }
            if (canSubmitForm) {
                const {payload, error, status} = await request(loginAction, clientContext)
                if (error && status === 500) {
                    toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                    setIsLoading(false)
                    return
                }
                if (!payload.hasOwnProperty('token')) {
                    toast.error(payload.message)
                } else if (payload.hasOwnProperty('token')) {
                    // prihlasit pouzivatela
                    authContext.signIn(payload.token)
                    toast.success(USER_SUCCESSFULLY_LOGGED_IN)
                    navigate(locationTo)
                }
            } else {
                toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
            }
        } else {
            toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
        }
        setIsLoading(false)
    }

    // effect - check ci je user prihlaseny
    React.useEffect(() => {
        const isUserSignedIn = async (): Promise<boolean> => {
            return await authContext.isUserSignedIn()
        }
        isUserSignedIn().then((isUserSignedIn: boolean) => {
            setIsUserLoggedIn(isUserSignedIn)
            setIsLoading(false)
        })
    }, [])

    // loading
    if (isLoading) {
        return (
            <Loading />
        )
    }

    // check, ci je user prihlaseny
    if (isUserLoggedIn) {
        return (
            <Container sx={{mt: '4%', mb: '2%'}}>
                <Box display='flex' justifyContent='center' alignItems='start'>
                    <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}}
                                className='text-primary'>
                        Už ste prihlasený. Prejdite prosím na hlavnú stránku kliknutím na tento <Link to='/'>link</Link>
                    </Typography>
                </Box>
            </Container>
        )
    }

    return (
        <Container sx={{mt: '4%', mb: '2%'}}>
            <Box display='flex' justifyContent='center' alignItems='center'>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px'}}>
                        <div className="w-100 text-center">
                            <Typography variant='h6' component='div' style={{fontFamily: 'OpenSansBold'}}
                                        className='text-primary'>
                                Prihlásenie
                            </Typography>
                        </div>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className="form-group">
                                        <label htmlFor="email"
                                               className='required text-primary'
                                               style={{fontFamily: 'OpenSansBold'}}>
                                            Prihlasovacie meno
                                        </label>
                                        <input type="text"
                                               name="username"
                                               id="username"
                                               className={`form-control ${isUsernameValid ? 'border-primary' : 'error'} rounded-borders`}
                                               value={username ?? ''}
                                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
                                        />
                                        <div>
                                            {!isUsernameValid && (
                                                <span className="error">
                                                    Prihlasovacie meno je prázdne!
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className="form-group">
                                        <label
                                            htmlFor="password"
                                            className='required text-primary'
                                            style={{fontFamily: 'OpenSansBold'}}
                                        >
                                            Heslo
                                        </label>
                                        <div className='password-input-wrapper'>
                                            <div className='input-container'>
                                                <input type={passwordVisible ? 'text' : 'password'}
                                                       name="password"
                                                       id="password"
                                                       value={password ?? ''}
                                                       className={`form-control ${isPasswordValid ? 'border-primary' : 'error'} rounded-borders`}
                                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                                                />
                                                <div className='password-toggle-icon'
                                                     onClick={togglePasswordVisibility}>
                                                    {passwordVisible ? (
                                                        <Tooltip title='Skryť heslo'>
                                                            <VisibilityOff className='icon'/>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title='Zobraziť heslo'>
                                                            <Visibility className='icon'/>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {!isPasswordValid && (
                                                <span className="error">
                                                    Heslo je prázdne!
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className="form-group w-100 text-center">
                                        <button
                                            type="submit"
                                            className="background-primary rounded-borders border-primary"
                                            style={{fontFamily: 'OpenSansBold', fontSize: '18px', padding: '10px'}}
                                        >
                                            Prihlásiť sa
                                        </button>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} sx={{mb: '2%'}}>
                                    <div className="form-group w-100 text-center">
                                        <Link to='/reset-password-request'>
                                            Zabudnuté heslo
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="w-100 text-center">
                                <p className='text-primary' style={{fontFamily: 'OpenSansBold'}}>
                                    Ak ešte nemáte účet, zaregistrujte sa kliknutím na tento&nbsp;
                                    <Link to='/registration'>
                                        link
                                    </Link>
                                </p>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    )
}

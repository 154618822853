import * as React from 'react'
import { Action, ClientContext } from 'react-fetching-library'
import { AuthContext } from '../../App'
import AddOrEditFaqItem from "../../components/faqItem/AddOrEditFaqItem"
import FaqItemInterface from '../../types/FaqItem/FaqItemInterface'
import { useNavigate, useParams } from "react-router-dom"
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import { Container, Typography } from "@mui/material"
import Loading from '../../components/loader/Loading'
import ErrorComponent from "../../components/error/ErrorComponent"

export default function EditFaqItem(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const EDITED_FAQ_ITEM_SUCCESSFULLY: string = 'FAQ položka bola úspešne upravená'
    const EDITED_FAQ_ITEM_UNSUCCESSFULLY: string = 'FAQ položka sa nepodarilo upraviť'
    // state
    const [faqItem, setFaqItem] = React.useState<FaqItemInterface | null>(null)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isError, setIsError] = React.useState<boolean>(false)
    // context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)

    // params
    const params = useParams()
    const {id} = params
    // navigate
    const navigate = useNavigate()

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    React.useEffect(() => {
        const getFaqItem = async () => {
            const {payload, error, status} = await request(getFaqItemAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (status === 200) {
                    setFaqItem(payload)
                }
            }
            setIsLoading(false)
        }
        getFaqItem()
    }, [])

    React.useEffect(() => {
        if (!authContext.userLogged?.hasAccessToFaqAdministration) { // right - len ten user moze editovat faqItem-s ktory ma pristup do FAQ administracie
            // ak nema user pristup tak ho vratime na '/' - uvodna stranka
            navigate('/')
        }
    }, [authContext.userLogged])

    // actions
    const getFaqItemAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/faq/item/get/' + id),
        headers: {
            Accept: 'application/json',
        },
    }

    const editFaqItemAction: Action = {
        method: 'PUT',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/faq/item/edit/' + id),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json',
        }
    }

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <Container sx={{mt: '5%', mb: '3%'}}>
            <Typography
                variant='h5'
                component='div'
                style={{fontFamily: 'OpenSansBold'}}
                className='text-primary'
            >
                Úprava FAQ položky '{faqItem?.question}'
            </Typography>
            <AddOrEditFaqItem
                faqItem={faqItem}
                action={editFaqItemAction}
                errorKey={'faq_item_edit'}
                messageOnSuccess={EDITED_FAQ_ITEM_SUCCESSFULLY}
                messageOnFailure={EDITED_FAQ_ITEM_UNSUCCESSFULLY}
                urlToNavigateOnSucccess={'/view/faq-item/' + id}
            />
        </Container>
    )
}
import * as React from 'react'
import { useParams } from 'react-router-dom'
import UserInterface from '../../types/User/UserInterface'
import { Action, ClientContext } from "react-fetching-library"
import { AuthContext } from '../../App'
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import { Container, Typography } from '@mui/material'
import UserViewProfileOrEditProfile from "../../components/user/UserViewProfileOrEditProfile"
import Loading from "../../components/loader/Loading"
import ErrorComponent from "../../components/error/ErrorComponent"

export default function EditProfile(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const COULDNT_EDIT_USER: string = 'Neporadilo sa upraviť používateľa!'
    const USER_SUCCESSFULLY_EDITED: string = 'Používateľ úspešne upravený!'
    // init params
    const params = useParams()
    const {id} = params
    // state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [user, setUser] = React.useState<UserInterface | null>(null)
    const [isError, setIsError] = React.useState<boolean>(false)
    // context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)

    const getUserAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/get/' + id),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json'
        }
    }

    const editUserAction: Action = {
        method: 'PUT',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/edit/' + id),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json'
        },
    }

    React.useEffect(() => {
        const getUser = async (): Promise<void> => {
            const {payload, error, status} = await request(getUserAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (status === 200) {
                    setUser(payload)
                }
            }
            if (status === 401) {
                authContext.logout()
            }
            setIsLoading(false)
        }
        getUser()
    }, [])


    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            <Container sx={{mt: '5%', mb: '5%'}}>
                <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Upraviť použivateľa
                </Typography>
                <UserViewProfileOrEditProfile
                    userId={user!.id}
                    isView={false}
                    messageOnError={COULDNT_EDIT_USER}
                    messageOnSuccess={USER_SUCCESSFULLY_EDITED}
                    urlToRedirectToOnSuccess={'/users'}
                    action={editUserAction}
                />
            </Container>

        </React.Fragment>
    )
}

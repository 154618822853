import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Action, ClientContext } from "react-fetching-library"
import { toast } from 'react-toastify'
import ChangePassword from "../../components/changePassword/ChangePassword"
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import { Box, Container, Typography } from '@mui/material'
import Loading from "../../components/loader/Loading"

export default function ResetPassword(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const CANT_PROCCESS_REQUEST: string = 'Nepodarilo sa spracovať požiadavku, platnosť linku vypršala'
    // client context
    const clientContext = React.useContext(ClientContext)
    // state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isTokenValid, setIsTokenValid] = React.useState<boolean>(true)
    //- recaptcha
    const IS_RECAPTCHA_ENABLED: boolean = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true'
    // init params, navigate
    const params = useParams()
    const navigate = useNavigate()
    // vytiahneme z url parametrov token
    const {token} = params
    // actions
    const validateResetPasswordTokenAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/resetPassword/validateToken'),
        headers: {
            Accept: 'application/json',
        },
        body: {
            hashedToken: token
        }
    }

    const resetPasswordAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/resetPassword/reset'),
        headers: {
            Accept: 'application/json',
        },
        body: {
            hashedToken: token,
        }
    }

    // effect
    React.useEffect(() => {
        const validateToken = async (): Promise<void> => {
            const {payload, error} = await request(validateResetPasswordTokenAction, clientContext)
            if (error) {
                setIsTokenValid(false)
                navigate('/login')
                toast.error(CANT_PROCCESS_REQUEST)
            }
            if (payload.success === true) {
                // ak je token validny tak nastavime state
                setIsTokenValid(true)
            } else {
                // ak nie je validny tak nastavime state, a redirectneme het
                setIsTokenValid(false)
                navigate('/login')
                toast.error(CANT_PROCCESS_REQUEST)
            }
            setIsLoading(false)
        }
        validateToken()
    }, [])

    // loading
    if (isLoading) {
        return (
            <Loading/>
        )
    }

    return (
        <Container sx={{mt: '4%', mb: '3%'}}>
            <Box display='flex' justifyContent='center' alignItems='center'>
                {isTokenValid ? (
                    <ChangePassword
                        Action={resetPasswordAction}
                        redirectToUrl={'/login'}
                        errorKey={'reset_password_error'}
                        isRecaptchaEnabled={IS_RECAPTCHA_ENABLED}
                    />
                ) : (
                    <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                        Ospravdlňujeme sa, ale nastala chyba pri spracovaní požiadavky.
                    </Typography>
                )}
            </Box>
        </Container>
    )
}

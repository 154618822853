import * as React from 'react'
import { Action, ClientContext } from 'react-fetching-library'
import { useNavigate, useParams } from 'react-router-dom'
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import { AuthContext } from "../../App"
import Loading from '../../components/loader/Loading'
import ErrorComponent from "../../components/error/ErrorComponent"
import { Container, Typography } from '@mui/material'
import OrderInterface from '../../types/Order/OrderInterface'

export default function CreatePaymentPage(): JSX.Element {
    //- constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    //- state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isError, setIsError] = React.useState<boolean>(false)
    const [paymentButton, setPaymentButton] = React.useState<string | null>(null)
    const [order, setOrder] = React.useState<OrderInterface | null>(null)
    const [agreedToTerms, setAgreedToTerms] = React.useState<boolean>(false)
    //- init params
    const params = useParams()
    const {paymentId} = params
    //- context
    const authContext = React.useContext(AuthContext)
    const clientContext = React.useContext(ClientContext)
    const navigate = useNavigate()

    //- actions
    const createThePayPaymentAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl(`/api/payment/create`),
        headers: {
            Accept: 'application/json'
        },
        body: {
            paymentId: paymentId,
        }
    }

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    //- effect
    React.useEffect(() => {
        const createThePayPayment = async (): Promise<void> => {
            setIsLoading(true)
            const {payload, error} = await request(createThePayPaymentAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (payload.success) {
                    setPaymentButton(payload.data.paymentButton.replace('Pay!', 'Zaplatiť'))
                    setOrder(payload.data.payment.orderx)
                }
            }
            setIsLoading(false)
        }
        createThePayPayment()
    }, [])

    React.useEffect(() => {
        // disable the button
        const thePayButton = document.querySelector('*[data-thepay="payment-button"]')
        if (thePayButton) {
            if (!agreedToTerms) {
                thePayButton.classList.remove('not-disabled')
            } else {
                thePayButton.classList.add('not-disabled')
            }
        }
    }, [agreedToTerms])

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <Container sx={{mt: '2%', mb: '2%'}}>
            <div className="text-center">
                <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Zaplatiť objednávku s číslom {order?.number}
                </Typography>
                <div className="form-group">
                    <label htmlFor="agreedToTerms" className='text-primary'  style={{fontFamily: 'OpenSansBold'}}>
                        <input type="checkbox" name="agreedToTerms" id="agreedToTerms" checked={agreedToTerms}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgreedToTerms(e.target.checked)}/>
                        &nbsp;Súhlasím s <a href="/general-terms-and-conditions" target="_blank" className='text-primary'>obchodnými podmienkami</a>
                    </label>
                </div>
                {paymentButton !== null && (
                    <div dangerouslySetInnerHTML={{__html: paymentButton}}/>
                )}
            </div>
        </Container>
    )
}

import * as React from 'react'
import { Action, ClientContext } from 'react-fetching-library'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AuthContext } from '../../App'
import FaqItemInterface from '../../types/FaqItem/FaqItemInterface'
import UserLoggedInterface from "../../types/User/UserLoggedInterface"
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import { Box, Container, Tooltip, Typography, CardContent, IconButton, Collapse, Card } from '@mui/material'
import RemoveRounded from "@mui/icons-material/RemoveRounded"
import AddIcon from "@mui/icons-material/AddRounded"
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from "@mui/icons-material/Delete"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ConfirmModal from '../../components/modal/ConfirmModal'
import Loading from "../../components/loader/Loading"
import ErrorComponent from "../../components/error/ErrorComponent";

export default function ViewFaqItem(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const FAQ_ITEM_SUCCESSFULLY_DELETED: string = 'FAQ položka bola úspešne vymazaná!'
    // context
    const authContext = React.useContext(AuthContext)
    const clientContext = React.useContext(ClientContext)
    // state
    const [faqItem, setFaqItem] = React.useState<FaqItemInterface | null>(null)
    const [userLogged] = React.useState<UserLoggedInterface>(authContext.userLogged!)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [open, setOpen] = React.useState(true)
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const [faqItemToDelete, setFaqItemToDelete] = React.useState<FaqItemInterface | null>(null)
    const [isError, setIsError] = React.useState<boolean>(false)
    const {id} = useParams()
    const navigate = useNavigate()
    const getFaqItemAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/faq/item/get/' + id),
        headers: {
            Accept: 'application/json'
        },
    }

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    React.useEffect(() => {
        const fetchFaqItem = async () => {
            const {payload, error, status} = await request(getFaqItemAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (status === 200) {
                    setFaqItem(payload)
                }
            }
            setIsLoading(false)
        }
        fetchFaqItem()
    }, [])

    React.useEffect(() => {
        if (!userLogged.hasAccessToFaqAdministration) { // right - len ten user moze zobrazit jeden faqItem ktory ma pristup do FAQ administracie
            // ak nema user pristup tak ho vratime na '/' - uvodna stranka
            navigate('/')
        }
    }, [userLogged]);

    const handleEdit = () => {
        navigate(`/edit/faq-item/${id}`)
    }

    const handleDelete = (faqItem: FaqItemInterface) => {
        handleDelete2(faqItem.id)
        hideModalAndClearFaqItemToDelete()
    }

    const handleDelete2 = async (id: number) => {
        setIsLoading(true)
        const deleteFaqItemAction: Action = {
            method: 'DELETE',
            endpoint: getApiUrlFromRelativeUrl(`/api/secured/faq/item/delete/${id}`),
            headers: {
                Authorization: `Bearer ${authContext.jwtToken}`
            }
        }
        const { error, status } = await request(deleteFaqItemAction, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        } else {
            if (status === 204) {
                toast.success(FAQ_ITEM_SUCCESSFULLY_DELETED)
                navigate('/administration-faq')
            } else {
                toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
            }
        }
        if (status === 401) {
            authContext.logout()
        }
        setIsLoading(false)
    }

    const hideModalAndClearFaqItemToDelete = () => {
        setIsModalOpen(false)
        setFaqItemToDelete(null)
    }

    const askDeleteQuestion = () => {
        setFaqItemToDelete(faqItem)
        setIsModalOpen(true)
    }

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            <ConfirmModal
                show={isModalOpen}
                onCloseCallback={() => hideModalAndClearFaqItemToDelete()}
                onSuccessCallback={() => handleDelete(faqItemToDelete as FaqItemInterface)}
                message={`Naozaj chcete vymazať FAQ položku ${faqItemToDelete?.question ?? ''}?`}
                title='Vymazanie FAQ položky'
            />
            <Container sx={{mt: '5%'}}>
                <Typography
                    variant='h5'
                    component='div'
                    style={{fontFamily: 'OpenSansBold'}}
                    className='text-primary mb-2'
                >
                    Detail FAQ položky '{faqItem?.question}'
                    <Tooltip title='Upraviť FAQ položku'>
                        <IconButton aria-label="Upraviť FAQ položku"
                                    onClick={handleEdit}>
                            <EditIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Zmazať FAQ položku">
                        <IconButton aria-label="Zmazať FAQ položku"
                                    onClick={askDeleteQuestion}
                        >
                            <DeleteIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                </Typography>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '135px 120px 171px 171px / 130px 150px 150px 140px'}}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box>
                                <Typography variant="h6">
                                    {faqItem?.question}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={() => setOpen(!open)}
                                    style={{marginRight: '10px', fontSize: '18px'}}
                                    aria-label="expand"
                                    size="small"
                                >
                                    {open ? (
                                        <RemoveRounded style={{color: '#DADADA', fontSize: 32}}/>
                                    ) : (
                                        <AddIcon style={{color: '#DADADA', fontSize: 30}}/>
                                    )}
                                </IconButton>
                            </Box>
                        </Box>
                    </CardContent>
                    <div style={{backgroundColor: '#FFFFFF', color: '#808080'}}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <CardContent style={{
                                border: '1px solid #E8E8E8',
                                borderRadius: '31px 38px 55px 37px / 47px 36px 45px 49px',
                                marginTop: '15px'
                            }}>
                                <Container sx={{height: 'auto', lineHeight: 2}}>
                                    {faqItem?.answer}
                                </Container>
                            </CardContent>
                        </Collapse>
                    </div>
                </Card>
            </Container>
            <div className="text-center mt-4 mb-3">
                <Link to='/administration-faq' className='background-primary rounded-borders border-primary' style={{padding: '5px', textDecoration: 'none'}}>
                    <ArrowBackIcon/>
                    <span style={{fontFamily: 'OpenSansBold'}}>
                        Späť na zoznam FAQ položiek
                    </span>
                </Link>
            </div>
        </React.Fragment>
    )
}

import * as React from 'react'
import { Action, ClientContext } from "react-fetching-library"
import { toast } from "react-toastify"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Loading from '../loader/Loading'

interface AdvertisementContactPersonFormProps {
    autoCheckCheckbox: boolean, // defaultne asi by malo byt true - vid. obchodne podmienky
    advertisementId: number,
}

interface AdvertisementContactPersonFormData {
    fullName: string | null,
    email: string | null,
    phone: string | null,
    consent: boolean,
}

interface AdvertisementContactPersonFormErrors {
    fullNameErrors: string[],
    emailErrors: string[],
    phoneErrors: string[],
    consentErrors: string[],
}

export default function AdvertisementContactPersonForm(props: AdvertisementContactPersonFormProps): JSX.Element {
    //- constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const CONTACT_PERSON_SUCCESFULLY_CONTACTED: string = 'Email bol úspešne odoslaný kontaktnej osobe'
    const COULDNT_CONTACT_ADVERTISEMENT_CONTACT_PERSON: string = 'Nepodarilo sa odoslať email kontaktnej osobe'
    const VERIFY_YOU_ARE_NOT_A_ROBOT: string = 'Overte, že nie ste robot!'
    //- state
    const [formData, setFormData] = React.useState<AdvertisementContactPersonFormData>({
        fullName: null,
        email: null,
        phone: null,
        consent: false,
    })
    const [errors, setErrors] = React.useState<AdvertisementContactPersonFormErrors>({
        fullNameErrors: [],
        emailErrors: [],
        phoneErrors: [],
        consentErrors: [],
    })
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    //- recaptcha
    const IS_RECAPTCHA_ENABLED: boolean = process.env.REACT_APP_IS_RECAPTCHA_ENABLED === 'true'
    const {executeRecaptcha} = useGoogleReCaptcha()
    //- destructure from state
    const {
        fullName,
        email,
        phone,
        consent
    } = formData
    //- destructure from errors
    const {
        fullNameErrors,
        emailErrors,
        phoneErrors,
        consentErrors
    } = errors
    //- context
    const clientContext = React.useContext(ClientContext)

    //- action
    const contactAdvertisementContactPersonAction: Action = {
        endpoint: getApiUrlFromRelativeUrl(`/api/advertisement/contact/advertisement/contact/person`),
        method: 'PUT',
        body: {
            advertisementId: props.advertisementId,
            fullName,
            email,
            phone,
            confirm: props.autoCheckCheckbox ? true : consent,
        },
        headers: {
            Accept: 'application/json'
        },
    }

    const verifyRecaptchaAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/recaptcha/verify'),
        headers: {
            Accept: 'application/json'
        },
    }

    //- handlers
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name !== 'consent') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.checked
            })
        }
    }

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (props.autoCheckCheckbox) {
            setFormData({
                ...formData,
                ['consent']: true, // aj ked user nezaskrtol checkbox, tak ho zaskrtneme za neho, vid. obchodne podmienky.
            })
        }
        setIsLoading(true)
        const recaptchaToken = await executeRecaptcha?.('advertisementContactPersonForm')

        if (recaptchaToken || !IS_RECAPTCHA_ENABLED) {
            //- verify recaptcha
            const verifyRecaptchaAction2: Action = {
                ...verifyRecaptchaAction,
                body: {
                    recaptchaToken: recaptchaToken,
                }
            }
            let canSubmitForm: boolean = false
            if (IS_RECAPTCHA_ENABLED) {
                const { error, status} = await request(verifyRecaptchaAction2, clientContext)
                if (error) {
                    toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
                    setIsLoading(false)
                    return
                }
                canSubmitForm = status === 200 || status === 201
            } else {
                canSubmitForm = true
            }
            if (canSubmitForm) {
                const {payload, error} = await request(contactAdvertisementContactPersonAction, clientContext)
                if (error) {
                    toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                    setIsLoading(false)
                    return
                }
                const fullNameErrorsNew: any[] = []
                const emailErrorsNew = []
                const phoneErrorsNew = []
                const consentErrorsNew = []
                if (payload.success) {
                    toast.success(CONTACT_PERSON_SUCCESFULLY_CONTACTED)
                    //- vycistime form
                    setFormData({
                        fullName: null,
                        email: null,
                        phone: null,
                        consent: false,
                    })
                } else {
                    toast.error(COULDNT_CONTACT_ADVERTISEMENT_CONTACT_PERSON)
                    //- nastavime errory
                    for (let key in payload.errors) {
                        switch (key) { // takyto krepy switch zatial, mozno v buducnosti prepisat na nieco rozumnejsie
                            case 'fullName':
                                fullNameErrorsNew.push(payload.errors[key])
                                break
                            case 'email':
                                emailErrorsNew.push(payload.errors[key])
                                break
                            case 'phone':
                                phoneErrorsNew.push(payload.errors[key])
                                break
                            case 'confirm':
                                consentErrorsNew.push(payload.errors[key])
                                break
                            default:
                                break
                        }
                    }
                }
                //- setneme do state errors
                setErrors({
                    fullNameErrors: fullNameErrorsNew,
                    emailErrors: emailErrorsNew,
                    phoneErrors: phoneErrorsNew,
                    consentErrors: consentErrorsNew,
                })
            } else {
                toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
            }
        } else {
            toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
        }
        setIsLoading(false)
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <form onSubmit={onSubmit}>
                <div className="form-group mb-2">
                    <input type="text"
                           className={`${fullNameErrors.length > 0 && 'error '} form-control rounded-borders border-primary`}
                           id="fullName"
                           value={fullName ?? ''}
                           name='fullName'
                           onChange={onChange} placeholder="Meno a priezvisko"/>
                    <div>
                        {fullNameErrors.length > 0 && (
                            <React.Fragment>
                                {fullNameErrors.map((e: string) => (
                                    <p className='error'>{e}</p>
                                ))}
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="form-group mb-2">
                    <input type="email"
                           className={`${emailErrors.length > 0 && 'error '} form-control rounded-borders border-primary`}
                           name="email"
                           id="email"
                           value={email ?? ''}
                           onChange={onChange}
                           placeholder="Email"/>
                    <div>
                        {emailErrors.length > 0 && (
                            <React.Fragment>
                                {emailErrors.map((e: string) => (
                                    <p className='error'>{e}</p>
                                ))}
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="form-group mb-2">
                    <input
                        className={`${phoneErrors.length > 0 && 'error '} form-control rounded-borders border-primary`}
                        name="phone"
                        id="phone"
                        value={phone ?? ''}
                        onChange={onChange}
                        placeholder="Tel. číslo"/>
                    <div>
                        {phoneErrors.length > 0 && (
                            <React.Fragment>
                                {phoneErrors.map((e: string) => (
                                    <p className='error'>{e}</p>
                                ))}
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor='consent'>
                        <input type="checkbox" className={`${consentErrors.length > 0 && 'error '}`}
                               checked={consent} id="consent" name="consent" onChange={onChange}/>
                        <small style={{fontSize: '12px', marginLeft: '5px'}}>
                            Súhlasím so spracovaním osobných údajov
                        </small>
                    </label>
                    <div>
                        {consentErrors.length > 0 && (
                            <React.Fragment>
                                {consentErrors.map((e: string) => (
                                    <p className='error'>{e}</p>
                                ))}
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="text-center">
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary rounded-borders"
                                style={{fontFamily: 'OpenSansSemiBold', fontSize: '20px'}}>
                            Odoslať
                        </button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}
import * as React from 'react'
import { Container, Grid, Typography } from "@mui/material"
import { AdvertisementInterface } from "../../types/Advertisement/AdvertisementInterface"
import MyAdvertisementsCard from "./MyAdvertisementsCard"
import { Action, ClientContext } from "react-fetching-library"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { AuthContext } from '../../App'
import {getApiUrlFromRelativeUrl, request} from '../../utils/api/ApiUtil'
import ConfirmModal from '../modal/ConfirmModal'
import IconButton from "@mui/material/IconButton"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { PackageInterface } from "../../types/package/PackageInterface"
import Loading from "../loader/Loading"

interface Props {
    action: Action,
    canEditAdvertisements: boolean,
    canDeleteAdvertisements: boolean,
}

export default function MyAdvertisementsCardList(props: Props): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const CANNOT_EDIT_ADVERTISEMENT_MESSAGE: string = 'Nemáte oprávnenie upravovať inzeráty!'
    const CANNOT_DELETE_ADVERTISEMENT_MESSAGE: string = 'Nemáte oprávnenie mazať inzeráty!'
    const DELETE_ADVERTISEMENT_SUCCESS_MESSAGE: string = 'Inzerát bol úspešne zmazaný!'
    const SET_AS_SOLD_SUCCESS_MESSAGE: string = 'Inzerát bol úspešne označený ako úspešný!'
    const SET_AS_SOLD_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba pri označovaní inzerátu ako úspešný!'
    // state
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const [advertisements, setAdvertisements] = React.useState<AdvertisementInterface[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [limit, setLimit] = React.useState<number>(10) // limit zatial nemenime
    const [page, setPage] = React.useState<number>(1)
    const [total, setTotal] = React.useState<number>(0)
    const [modalAdvertisement, setModalAdvertisement] = React.useState<AdvertisementInterface | null>(null)
    const [packages, setPackages] = React.useState<PackageInterface[]>([])
    const [setAsSoldModalOpen, setSetAsSoldModalOpen] = React.useState<boolean>(false)
    const [isError, setIsError] = React.useState<boolean>(false)
    // context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)
    // navigate
    const navigate = useNavigate()

    // actions
    const deleteAdvertisementAction: Action = {
        method: 'DELETE',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/advertisement/delete`), // id sa pridava v handleri pred requestom
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json'
        }
    }

    const getPackagesAllAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/package/getAll/notPaginated'),
        headers: {
            Accept: 'application/json'
        }
    }

    const setAdvertisementAsSoldAction: Action = {
        method: 'PUT',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/advertisement/set/sold'), // id sa pridava v handleri pred requestom
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json'
        }
    }

    const getAdvertisements = async () => {
        setIsLoading(true)
        const action2 = {
            ...props.action,
            endpoint: `${props.action.endpoint}/${limit}/${page}`
        }
        const {payload, error, status} = await request(action2, clientContext)
        if (error) {
            setIsError(true)
        } else {
            if (payload.success) {
                setAdvertisements([...advertisements, ...payload.data.pagination])
                setTotal(payload.data.total)
            }
        }
        if (status === 401) {
            authContext.logout()
        }
        setIsLoading(false)
    }


    React.useEffect(() => {
        const getPackages = async (): Promise<void> => {
            setIsLoading(true)
            const {payload, error} = await request(getPackagesAllAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                setPackages(payload)
            }
            setIsLoading(false)
        }
        getPackages()
    }, [])

    React.useEffect(() => {
        getAdvertisements()
    }, [page])

    const handleEdit = (advertisement: AdvertisementInterface) => {
        if (!props.canEditAdvertisements) {
            toast.error(CANNOT_EDIT_ADVERTISEMENT_MESSAGE)
            return
        }
        navigate(`/advertisement/edit/${advertisement.id}`)
    }

    const askDeleteQuestion = (advertisement: AdvertisementInterface) => {
        setModalAdvertisement(advertisement)
        setIsModalOpen(true)
    }

    const handleDelete = async (advertisement: AdvertisementInterface) => {
        handleDelete2(advertisement) // vymazeme inzerat
        hideModalAndClearAdvertisementToDelete() // skryjeme modal a vymazeme inzerat z state
    }

    const handleDelete2 = async (advertisement: AdvertisementInterface) => {
        if (!props.canDeleteAdvertisements) {
            toast.error(CANNOT_DELETE_ADVERTISEMENT_MESSAGE)
            return
        }
        const action: Action = {
            ...deleteAdvertisementAction,
            endpoint: `${deleteAdvertisementAction.endpoint}/${advertisement.id}`
        }
        setIsLoading(true)
        const {error, status} = await request(action, clientContext)
        if (error && status === 500) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        } else {
            if (status === 204) {
                toast.success(DELETE_ADVERTISEMENT_SUCCESS_MESSAGE)
                setAdvertisements(advertisements.filter((advertisement2: AdvertisementInterface) => advertisement2.id !== advertisement.id))
            }
        }
        if (status === 401) {
            authContext.logout()
        }
        setIsLoading(false)
    }

    const hideModalAndClearAdvertisementToDelete = () => {
        setIsModalOpen(false)
        setModalAdvertisement(null)
    }

    const hideSetAsSoldModalAndClearModalAdvertisement = (): void => {
        setSetAsSoldModalOpen(false)
        setModalAdvertisement(null)
    }

    const askSetAsSoldQuestion = (advertisement: AdvertisementInterface): void => {
        setModalAdvertisement(advertisement)
        setSetAsSoldModalOpen(true)
    }

    const handleSetAsSold = async (advertisement: AdvertisementInterface) => {
        const setAsSoldAction2 = {
            ...setAdvertisementAsSoldAction,
            body: {
                advertisementId: advertisement.id
            }
        }
        const {payload, error} = await request(setAsSoldAction2, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        } else {
            if (payload.success) {
                toast.success(SET_AS_SOLD_SUCCESS_MESSAGE)
                hideSetAsSoldModalAndClearModalAdvertisement()
            } else {
                toast.error(SET_AS_SOLD_ERROR_MESSAGE)
            }
        }
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            {/* delete modal */}
            <ConfirmModal
                show={isModalOpen}
                onCloseCallback={() => hideModalAndClearAdvertisementToDelete()}
                onSuccessCallback={() => handleDelete(modalAdvertisement as AdvertisementInterface)}
                message={`Naozaj chcete zmazať ponuku ${modalAdvertisement?.subject}?`}
                title='Zmazať inzerát'
            />
            {/* set as sold modal */}
            <ConfirmModal
                show={setAsSoldModalOpen}
                onCloseCallback={() => hideSetAsSoldModalAndClearModalAdvertisement()}
                onSuccessCallback={() => handleSetAsSold(modalAdvertisement as AdvertisementInterface)}
                message={`Naozaj chcete nastaviť ponuku ${modalAdvertisement?.subject} ako úspešnú?`}
                title='Nastaviť ponuku ako úspešnú'
            />
            <div>
                {advertisements.length === 0 && (
                    <div className="text-center">
                        <Typography
                            variant='h6'
                            component='div'
                            style={{fontFamily: 'OpenSansBold'}}
                            className='text-primary'
                        >
                            Žiadne inzeráty neboli nájdené.
                        </Typography>
                    </div>
                )}
                <Container>
                    <Grid container spacing={2}>
                        {advertisements.map((advertisement) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                key={advertisement.id}
                            >
                                <MyAdvertisementsCard
                                    advertisement={advertisement}
                                    packages={packages}
                                    canEdit={props.canEditAdvertisements}
                                    canDelete={props.canDeleteAdvertisements}
                                    handleClick={() => navigate(`/advertisement/view/${advertisement.id}`)}
                                    handleEdit={() => handleEdit(advertisement)}
                                    handleDelete={() => askDeleteQuestion(advertisement)}
                                    handleSetAsSold={() => askSetAsSoldQuestion(advertisement)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <div className="text-center">
                    {advertisements.length !== 0 && total === advertisements.length && (
                        <Typography
                            variant='h6'
                            component='div'
                            style={{fontFamily: 'OpenSansBold'}}
                            className='text-primary'
                        >
                            Boli načítané {advertisements.length} z {total} inzerátov.
                        </Typography>
                    )}
                    {advertisements.length !== total && (
                        <IconButton
                            aria-label="Načítať dalšie inzeráty"
                            onClick={() => setPage(page + 1)}
                            style={{fontSize: '25px'}}
                        >
                            <KeyboardArrowDownIcon
                                className='clickable load-more-icon'
                            />
                        </IconButton>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

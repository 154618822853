import * as React from 'react'
import { useNavigate, useParams } from "react-router-dom"
import Packages from "../../components/package/Packages"
import { AuthContext } from "../../App"
import { Container, Typography } from '@mui/material'

export default function AdvertisementCreatedPickPackagePage(): JSX.Element {
    //- init navigate, params, getParams
    const navigate = useNavigate()
    const params = useParams()
    const {advertisementId} = params
    //- context
    const authContext = React.useContext(AuthContext)

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    return (
        <Container sx={{mt: '10%', mb: '2%'}}>
            <Typography variant='h6' style={{fontFamily: 'OpenSansSemiBold'}} className='text-primary'>
                Inzerát bol úspešne vytvorený
            </Typography>
            <p>
                Ak chcete aby mal Váš inzerát väčšiu šancu na úspech, vyberte si balík, ktorý vám vyhovuje, alebo pokračujte na zobrazenie inzerátu
            </p>
            <Packages advertisementId={parseInt(advertisementId as string)}
                      userId={authContext.userLogged?.id ?? 0}
                      jwtToken={authContext.jwtToken ?? ''}
            />
            <div className="text-center mt-5">
                <button
                    onClick={() => navigate(`/advertisement/view/${advertisementId}`)}
                    className="background-primary rounded-borders primary-border"
                    style={{fontFamily: 'OpenSansSemiBold', fontSize: '20px', padding: '5px'}}
                >
                    Zobraziť inzerát
                </button>
            </div>
        </Container>
    )
}

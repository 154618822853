import * as React from 'react'
import { Typography } from "@mui/material"

interface ErrorComponentProps {
    message: string
}

export default function ErrorComponent(props: ErrorComponentProps): JSX.Element {
    return (
        <div className='text-center'>
            <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                {props.message}
            </Typography>
        </div>
    )
}

import { AuthContext } from '../../App'
import { Action } from "react-fetching-library"
import { useNavigate } from 'react-router-dom'
import { getApiUrlFromRelativeUrl } from "../../utils/api/ApiUtil"
import { Container, Typography } from '@mui/material'
import * as React from 'react'
import UserLoggedInterface from '../../types/User/UserLoggedInterface'
import AddOrEditFaqItem from "../../components/faqItem/AddOrEditFaqItem"
import Loading from "../../components/loader/Loading"

export default function AddFaqItemPage(): JSX.Element {
    // constants
    const FAQ_ITEM_SUCCESSFULLY_CREATED_MESSAGE: string = 'FAQ polozka bola uspesne vytvorena!'
    const COULDNT_CREATE_FAQ_ITEM_MESSAGE: string = 'Nepodarilo sa vytvorit FAQ polozku!'
    // state
    const authContext = React.useContext(AuthContext)
    const [userLogged] = React.useState<UserLoggedInterface>(authContext.userLogged!)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    // navigate
    const navigate = useNavigate()
    // actions
    const addFaqItemAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/faq/item/add'),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json',
        }
    }

    // effect
    React.useEffect(() => {
        if (!userLogged.hasAccessToFaqAdministration) { // right - len ten user moze pridavat faqItem-s ktory ma pristup do FAQ administracie
            // ak nema user pristup tak ho vratime na '/' - uvodna stranka
            navigate('/')
        }
        // inak vypneme loading
        setIsLoading(false)
    }, [userLogged])

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    return (
        <React.Fragment>
            <Container sx={{mt: '5%'}}>
                <Typography
                    variant='h5'
                    component='div'
                    style={{fontFamily: 'OpenSansBold'}}
                    className='text-primary'
                >
                    Pridať FAQ položku
                </Typography>
                <AddOrEditFaqItem faqItem={null}
                                  errorKey={'faq_item_add'}
                                  messageOnSuccess={FAQ_ITEM_SUCCESSFULLY_CREATED_MESSAGE}
                                  messageOnFailure={COULDNT_CREATE_FAQ_ITEM_MESSAGE}
                                  action={addFaqItemAction}
                                  urlToNavigateOnSucccess={'/administration-faq'}
                />
            </Container>
        </React.Fragment>
    )
}

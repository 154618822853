import * as React from 'react'
import {Container, Typography} from "@mui/material"

export default function GdprPage(): JSX.Element {
    return (
        <Container sx={{mt: '2%'}}>
            <div className='text-center'>
                <Typography variant='h6' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    ZÁSADY OCHRANY OSOBNÝCH ÚDAJOV SPOLOČNOSTI RDO s.r.o.
                </Typography>
                <p>V&aacute;žen&iacute; použ&iacute;vatelia,</p>
                <p>tieto z&aacute;sady ochrany osobn&yacute;ch &uacute;dajov sa vzťahuj&uacute; na
                    v&aacute;s &ndash; použ&iacute;vateľov na&scaron;ich inzertn&yacute;ch služieb
                    prev&aacute;dzkovan&yacute;ch na na&scaron;ich jednotliv&yacute;ch online weboch a v
                    mobiln&yacute;ch
                    aplik&aacute;ci&aacute;ch. Patr&iacute;me medzi jedin&yacute;ch,
                    ktor&yacute; sumarizuj&uacute; v&scaron;etky uskutočňovan&eacute; dražby a&nbsp;predaje majetku
                    v&nbsp;zmysle z&aacute;kona č. /2005 Z.z., 257/2002 Z.z. a&nbsp;233/1995 Z.z. na Slovensku.
                    Prev&aacute;dzkujeme online web zameran&yacute; na inzerciu t&yacute;chto
                    zosumarizovan&yacute;ch &uacute;dajoch o&nbsp;predajoch majetku. Pln&iacute;me funkciu
                    sprostredkovateľa
                    medzi stranou ponuky a stranou dopytu. Prostredn&iacute;ctvom vytvorenia technologick&eacute;ho
                    z&aacute;zemia a komunikačn&yacute;ch prostriedkov sprostredkov&aacute;vame v&aacute;m
                    použ&iacute;vateľom možnosť pon&uacute;kať na predaj jednotliv&eacute; kateg&oacute;rie tovaru, na
                    ktor&yacute; je konkr&eacute;tny port&aacute;l zameran&yacute; a možnosť nakupovať tento tovar
                    ďal&scaron;&iacute;m použ&iacute;vateľom. Z&aacute;roveň umožňujeme na na&scaron;ich
                    inzertn&yacute;ch
                    port&aacute;loch inzerovať jednotliv&eacute; tovary aj podnikateľom, ktor&yacute;ch predmetom
                    podnikania
                    je predaj tovaru. Pri poskytovan&iacute; na&scaron;ich služieb a produktov ako aj pri ich
                    inov&aacute;cii vždy berieme na zreteľ va&scaron;e s&uacute;kromie. Uvedomujeme si svoj
                    z&aacute;v&auml;zok voči svojim už&iacute;vateľom. Plne si uvedomujeme na&scaron;e
                    z&aacute;v&auml;zky
                    voči v&aacute;m prameniace z požiadaviek pr&aacute;vnych predpisov reguluj&uacute;cich va&scaron;e
                    s&uacute;kromie a ochranu va&scaron;ich osobn&yacute;ch &uacute;dajov. K ochrane va&scaron;ich
                    osobn&yacute;ch &uacute;dajov pristupujeme s re&scaron;pektom a zodpovedne. Preto spolupracujeme s
                    odborn&iacute;kmi z r&ocirc;znych oblast&iacute; napr. z oblasti pr&aacute;vnej, bezpečnostnej, IT,
                    aby
                    sme sa ubezpečili, že žiadne rozhodnutie sa neprijme bez re&scaron;pektovania s&uacute;kromia. V
                    r&aacute;mci na&scaron;ich služieb m&ocirc;žete spravovať svoje s&uacute;kromie r&ocirc;znym
                    sp&ocirc;sobom. Z&aacute;roveň m&aacute;te vždy možnosť využiť svoje pr&aacute;va
                    vypl&yacute;vaj&uacute;ce z Nariadenia Eur&oacute;pskeho parlamentu a Rady (E&Uacute;) 2016/679 z
                    27.
                    apr&iacute;la 2016 o ochrane fyzick&yacute;ch os&ocirc;b pri
                    sprac&uacute;van&iacute; osobn&yacute;ch &uacute;dajov a o voľnom pohybe
                    tak&yacute;chto &uacute;dajov,
                    ktor&yacute;m sa ru&scaron;&iacute; smernica 95/46/ES (v&scaron;eobecn&eacute; nariadenie o
                    ochrane &uacute;dajov) (&bdquo;Nariadenie&ldquo;) a z&aacute;kona č. 18/2018 Z. z. o ochrane
                    osobn&yacute;ch &uacute;dajov a o zmene a doplnen&iacute; niektor&yacute;ch z&aacute;konov
                    (&bdquo;Z&aacute;kon&ldquo;). &Uacute;čelom t&yacute;chto z&aacute;sad ochrany
                    osobn&yacute;ch &uacute;dajov je poskytnutie inform&aacute;cii o tom, kto, prečo, ako
                    sprac&uacute;va
                    va&scaron;e osobn&eacute; &uacute;daje, komu s&uacute; va&scaron;e osobn&eacute; &uacute;daje
                    poskytovan&eacute;, ak&eacute; m&aacute;te pr&aacute;va vypl&yacute;vaj&uacute;ce z Nariadenia a
                    Z&aacute;kona, ako ich m&ocirc;žete uplatniť.</p>
                <p style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Kto sme?
                </p>
                <p>Va&scaron;e osobn&eacute; &uacute;daje s&uacute; sprac&uacute;van&eacute; a
                    chr&aacute;nen&eacute; spoločnosťou RDO s.r.o., so s&iacute;dlom Tov&aacute;rensk&aacute; 2590/5,
                    811 09
                    Bratislava-mestsk&aacute; časť Star&eacute; Mesto, IČO: <strong>55 506 305</strong>,
                    zap&iacute;san&aacute; v Obchodnom registri Mestsk&eacute;ho s&uacute;du Bratislava III, oddiel:
                    Sro,
                    vložka č.: 170399/B. Z pohľadu Z&aacute;kona a Nariadenia m&aacute;me postavenie
                    prev&aacute;dzkovateľa
                    alebo aj sprostredkovateľa.</p>
                <p style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Ak&eacute; typy inform&aacute;cii o v&aacute;s zhromažďujeme?
                </p>
                <p>Preto, aby sme v&aacute;m mohli poskytn&uacute;ť na&scaron;e služby ako aj preto, aby sme mohli
                    na&scaron;e služby neust&aacute;le zlep&scaron;ovať, zhromažďujeme o v&aacute;s inform&aacute;cie.
                    Inform&aacute;cie, ktor&eacute; o v&aacute;s zhromažďujeme, a sp&ocirc;sob ich využitia
                    z&aacute;vis&iacute; od toho, ak&uacute; na&scaron;u konkr&eacute;tnu službu použ&iacute;vate a
                    ak&yacute;m sp&ocirc;sobom spravujete ovl&aacute;danie svojej ochrany s&uacute;kromia u n&aacute;s.
                    V
                    z&aacute;sade je možn&eacute; inform&aacute;cie, ktor&eacute; o v&aacute;s sprac&uacute;vame
                    rozčleniť
                    nasledovne:</p>
                <ul>
                    <li>Inform&aacute;cie, ktor&aacute; n&aacute;m odovzd&aacute;vate v priamej s&uacute;vislosti s
                        konkr&eacute;tnou poskytovanou službou: - napr&iacute;klad meno, priezvisko, bydlisko,
                        fotografia -
                        inform&aacute;cie, ktor&eacute; poskytujete pri použ&iacute;van&iacute; inzertnej
                        služby &ndash; napr&iacute;klad fotografie, znenie v&aacute;&scaron;ho inzer&aacute;tu -
                        inform&aacute;cie poskytovan&eacute; pri na&scaron;ich platen&yacute;ch
                        služb&aacute;ch &ndash; platobn&eacute; inform&aacute;cie, napr&iacute;klad č&iacute;slo
                        platobnej
                        karty alebo in&eacute; finančn&eacute; inform&aacute;cie - inform&aacute;cie s&uacute;visiace s
                        va&scaron;imi dopytmi rie&scaron;en&yacute;mi na&scaron;ou z&aacute;kazn&iacute;ckou
                        podporou &ndash; napr. sťažnosti, reklam&aacute;cie, podnety, upozornenia
                    </li>
                    <li>Inform&aacute;cie, ktor&eacute; dost&aacute;vame aj z in&yacute;ch zdrojov a to: -
                        inform&aacute;cie
                        o v&aacute;s od in&yacute;ch použ&iacute;vateľov - od in&yacute;ch použ&iacute;vateľov
                        m&ocirc;žeme
                        o v&aacute;s zhromažďovať inform&aacute;cie napr&iacute;klad vtedy, keď sa na n&aacute;s
                        obr&aacute;tia kv&ocirc;li v&aacute;m, napr. keď n&aacute;s upozorňuj&uacute; na
                        určit&eacute; va&scaron;e konanie alebo v&aacute;s odpor&uacute;čaj&uacute; ako možn&yacute;ch
                        už&iacute;vateľov na&scaron;ich služieb
                    </li>
                    <li>Inform&aacute;cie z&iacute;skavan&eacute; v s&uacute;vislosti s použ&iacute;van&iacute;m
                        na&scaron;ich služieb a to: - inform&aacute;cie o va&scaron;ej interakcii s nami,
                        napr&iacute;klad
                        ak&yacute;m sp&ocirc;sobom boli využ&iacute;van&eacute; služby, sp&ocirc;sob prihl&aacute;senia,
                        z
                        ak&eacute;ho miesta ste nav&scaron;t&iacute;vili jednotliv&eacute; str&aacute;nky a ako dlho ste
                        ich
                        použ&iacute;vali, reakčn&eacute; časy, chyby preberania, sp&ocirc;sob z&iacute;skania
                        pr&iacute;stupu k služb&aacute;m a ukončenie ich použ&iacute;vania a pod. -
                        technick&eacute; &uacute;daje použ&iacute;van&yacute;ch zariaden&iacute; a nastavenia,
                        napr&iacute;klad IP adresa, nastavenia prehliadača, operačn&yacute; syst&eacute;m, platforma,
                        cookies; bliž&scaron;ie inform&aacute;cie k tomu, ako použ&iacute;vame cookies, n&aacute;jdete
                        tu -
                        inform&aacute;cie, potrebn&eacute; k tomu, aby ste mohli použ&iacute;vať
                        určit&eacute; funkcionality
                        na&scaron;ich služieb. Napr&iacute;klad aby ste mohli pohodlne a r&yacute;chlo pridať
                        inzer&aacute;t
                        alebo ho spravovať, mobiln&aacute; aplik&aacute;cia vyžaduje pr&iacute;stup k
                        v&aacute;&scaron;mu
                        fotoapar&aacute;tu a taktiež medi&aacute;lnemu &uacute;ložisku. Pre zistenie kompatibility
                        v&aacute;&scaron;ho zariadenia s na&scaron;imi aplik&aacute;ciami potrebujeme pr&iacute;stup k
                        č&iacute;taniu stavu a identity zariadenia. Ďalej m&aacute; aplik&aacute;cia povolenie k
                        pr&iacute;stupu sieťov&eacute;ho pripojenia (Wi-Fi a mobiln&eacute; d&aacute;ta),
                        ovl&aacute;daniu
                        vibrovania, zabr&aacute;niť zariadeniu prejsť do režimu sp&aacute;nku,
                        prij&iacute;mať &uacute;daje
                        z internetu.
                    </li>
                </ul>
                <p style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Prečo sprac&uacute;vame va&scaron;e osobn&eacute; &uacute;daje?
                </p>
                <p>Na to, aby sme mohli va&scaron;e osobn&eacute; &uacute;daje sprac&uacute;vať v s&uacute;lade so
                    z&aacute;konom, je potrebn&eacute;, aby sme mali vopred stanoven&yacute; &uacute;čel ich
                    sprac&uacute;vania. Niž&scaron;ie uv&aacute;dzame &uacute;čely, na
                    ktor&eacute; s&uacute; va&scaron;e
                    osobn&eacute; &uacute;daje z&iacute;skavan&eacute; a ďalej sprac&uacute;van&eacute;.</p>
                <ul>
                    <li>Na spravovanie v&aacute;&scaron;ho &uacute;čtu a poskytovanie na&scaron;ich služieb Vo
                        v&auml;č&scaron;ine pr&iacute;padov je pr&aacute;vnym d&ocirc;vodom, na z&aacute;klade
                        ktor&eacute;ho sprac&uacute;vame va&scaron;e osobn&eacute; &uacute;daje plnenie zmluvy o
                        poskytnut&iacute; služby alebo zmluvy o sprostredkovan&iacute; služby. Prostredn&iacute;ctvom
                        overenia v&aacute;&scaron;ho jedinečn&eacute;ho drazimto.sk &uacute;čtu m&ocirc;žete
                        napr&iacute;klad: - prid&aacute;vať va&scaron;e inzer&aacute;ty a spravovať ich. Pri
                        tomto &uacute;čele z&iacute;skavame teda va&scaron;e osobn&eacute; &uacute;daje, preto lebo
                        v&aacute;m poskytujeme na&scaron;u službu, vami slobodne zvolen&uacute; v s&uacute;lade s
                        na&scaron;imi podmienkami upravuj&uacute;cimi poskytovanie zvolenej služby. Akcept&aacute;ciou
                        na&scaron;ich podmienok upravuj&uacute;cich poskytovanie vami zvolenej služby, doch&aacute;dza
                        teda
                        medzi nami k uzatvoreniu zmluvy.
                    </li>
                    <li>Na plnenie z&aacute;konn&yacute;ch povinnost&iacute;. Osobitn&eacute; pr&aacute;vne predpisy
                        n&aacute;m ako poskytovateľovi služieb m&ocirc;žu ukladať r&ocirc;zne povinnosti,
                        za &uacute;čelom
                        ktor&yacute;ch je nevyhnutn&eacute; sprac&uacute;vanie va&scaron;ich
                        osobn&yacute;ch &uacute;dajov.
                        Napr&iacute;klad v s&uacute;lade s Občianskym z&aacute;konn&iacute;kom a predpismi
                        na &uacute;seku
                        ochrany spotrebiteľa, sprac&uacute;vame va&scaron;e osobn&eacute; &uacute;daje i
                        za &uacute;čelom
                        vybavenia pr&iacute;padn&yacute;ch reklam&aacute;cii. Ďal&scaron;&iacute;m pr&iacute;kladom,
                        kedy &uacute;čelom sprac&uacute;vania osobn&yacute;ch &uacute;dajov je plnenie
                        z&aacute;konn&yacute;ch povinnost&iacute; je z&aacute;kon o &uacute;čtovn&iacute;ctve, v zmysle
                        ktor&eacute;ho sme povinn&iacute; uchov&aacute;vať určit&eacute; &uacute;daje k platen&yacute;m
                        služb&aacute;m po dobu stanoven&uacute; v tomto z&aacute;kone.
                    </li>
                    <li>Na zhodnotenie, udržiavanie a vylep&scaron;ovanie na&scaron;ich služieb a v&yacute;voj
                        nov&yacute;ch
                        služieb. Na z&aacute;klade va&scaron;ich inform&aacute;ci&iacute; zaisťujeme, aby na&scaron;e
                        služby
                        fungovali tak, ako to od nich očak&aacute;vate, napr&iacute;klad sledujeme v&yacute;padky alebo
                        odstraňujeme probl&eacute;my, ktor&eacute; n&aacute;m nahl&aacute;site. Pomocou va&scaron;ich
                        inform&aacute;ci&iacute; sa tiež snaž&iacute;me o inov&aacute;ciu na&scaron;ich
                        služieb &ndash; napr&iacute;klad keď na z&aacute;klade va&scaron;ich podnetov zist&iacute;me,
                        potrebu skvalitnenia služby alebo zaznamen&aacute;me dopyt po novej službe alebo
                        zhodnot&iacute;me
                        potrebu vy&scaron;&scaron;ej ochrany použ&iacute;vateľov a podobne. Inform&aacute;cie,
                        ktor&eacute; zhromažďujeme v r&aacute;mci existuj&uacute;cich služieb, n&aacute;m
                        pom&aacute;haj&uacute; pri v&yacute;voji nov&yacute;ch služieb.
                    </li>
                    <li>Na poskytovanie prisp&ocirc;soben&yacute;ch služieb vr&aacute;tane obsahu a&nbsp;rekl&aacute;m.
                        Na
                        z&aacute;klade inform&aacute;cii od v&aacute;s sme schopn&iacute; pri
                        poskytovan&iacute; na&scaron;ich služieb zabezpečiť to, aby v&aacute;m bola
                        poskytnut&aacute; služba &scaron;it&aacute; na &bdquo;mieru&ldquo; va&scaron;im potreb&aacute;m
                        a
                        preferenci&aacute;m. Poskytujeme v&aacute;m prisp&ocirc;soben&yacute; obsah, napr.
                        prostredn&iacute;ctvom zobrazovania relevantn&yacute;ch
                        odpor&uacute;čan&iacute; relevantn&yacute;ch
                        a pre v&aacute;s zauj&iacute;mav&yacute;ch pon&uacute;k, o ktor&yacute;ch predpoklad&aacute;me,
                        že
                        by v&aacute;s mohli osloviť a zaujať, a in&yacute;ch podobn&yacute;ch opatren&iacute;,
                        ktor&eacute; v&aacute;m maj&uacute; uľahčiť využ&iacute;vanie na&scaron;ich služieb a
                        zlep&scaron;enie va&scaron;ej použ&iacute;vateľskej sk&uacute;senosti pri
                        využ&iacute;van&iacute; na&scaron;ich služieb. Cieľom spracovania va&scaron;ich
                        osobn&yacute;ch &uacute;dajov za t&yacute;mto &uacute;čelom je aj zjednodu&scaron;enie
                        využ&iacute;vania služieb na&scaron;ej spoločnosti, napr. uloženie va&scaron;ich
                        inzer&aacute;tov,
                        alebo uprednostňovan&yacute;ch sp&ocirc;sobov platby na &uacute;čel bud&uacute;cich platieb za
                        na&scaron;e služby alebo odpor&uacute;čanie relevantn&yacute;ch produktov. Inform&aacute;cie,
                        ktor&eacute; zhromažďujeme z va&scaron;ich r&ocirc;znych zariaden&iacute;, (verzia
                        operačn&eacute;ho
                        syst&eacute;mu, verziu aplik&aacute;cie a inform&aacute;cie o hardv&eacute;rovom
                        vybaven&iacute; zariadenia), n&aacute;m pom&aacute;haj&uacute; poskytovať
                        konzistentn&eacute; služby
                        vo v&scaron;etk&yacute;ch va&scaron;ich zariadeniach.
                    </li>
                    <li>Na presadzovanie bezpečnosti na&scaron;ich služieb, prevenciu zneužitia služieb a
                        pre&scaron;etrenie
                        poru&scaron;enia na&scaron;ich podmienok Z&aacute;lež&iacute; n&aacute;m na tom, aby na&scaron;e
                        služby boli bezpečn&eacute;. Za &uacute;čelom presadzovania bezpečnosti na&scaron;ich služieb
                        sprac&uacute;vame va&scaron;e osobn&eacute; &uacute;daje i v r&aacute;mci prevencie spamu,
                        phishingu, obťažovania, zneužitia va&scaron;ich použ&iacute;vateľsk&yacute;ch &uacute;čtov alebo
                        in&yacute;ch zak&aacute;zan&yacute;ch činnost&iacute;. V r&aacute;mci tohto &uacute;čelu
                        sprac&uacute;vania realizujeme n&aacute;&scaron; z&aacute;v&auml;zok voči v&aacute;m zabezpečiť
                        ochranu n&aacute;&scaron;ho IT prostredia proti &uacute;tokom a in&yacute;m z&aacute;sahom, tak
                        aby
                        použ&iacute;vanie na&scaron;ich služieb bolo bezpečn&eacute;. Db&aacute;me na to, aby boli
                        dodržiavan&eacute; podmienky poskytovania na&scaron;ich služieb zo strany in&yacute;ch
                        použ&iacute;vateľov, a tak bolo zabezpečen&eacute; hodnotn&eacute; a
                        bezpečn&eacute; použ&iacute;vanie služieb. Vďaka inform&aacute;ci&aacute;m z poč&iacute;tačov,
                        telef&oacute;nov a in&yacute;ch zariaden&iacute;, na ktor&yacute;ch in&scaron;talujete a
                        použ&iacute;vate na&scaron;e služby, v z&aacute;vislosti od povolen&iacute;, ktor&eacute; ste
                        udelili vieme zabezpečiť vy&scaron;&scaron;iu ochranu v&aacute;&scaron;ho s&uacute;kromia.
                        Z&iacute;skan&eacute; &uacute;daje použ&iacute;vame ako pom&ocirc;cku pri poskytovan&iacute; a
                        podpore na&scaron;ich služieb a taktiež za &uacute;čelom zvy&scaron;ovania va&scaron;ej
                        bezpečnosti.
                        Z&iacute;skan&eacute; &uacute;daje zo zariaden&iacute; n&aacute;m pom&aacute;haj&uacute; overiť
                        totožnosť použ&iacute;vateľa, pretože sa neust&aacute;le snaž&iacute;me chr&aacute;niť
                        v&aacute;&scaron; &uacute;čet prostredn&iacute;ctvom t&iacute;mu technikov a
                        technol&oacute;gi&iacute;, ako je napr&iacute;klad &scaron;ifrovanie komunik&aacute;cie aj
                        ďal&scaron;ieho obsahu (napr&iacute;klad fotografi&iacute;) prostredn&iacute;ctvom protokolu
                        HTTPS.
                    </li>
                </ul>
                <p style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Ak&yacute; je pr&aacute;vny z&aacute;klad spracovania va&scaron;ich osobn&yacute;ch &uacute;dajov v
                    zmysle Z&aacute;kona a Nariadenia?
                </p>
                <p>Z&aacute;konnosť sprac&uacute;vania va&scaron;ich osobn&yacute;ch &uacute;dajov je u n&aacute;s
                    od&ocirc;vodnen&aacute; nasledovn&yacute;mi pr&aacute;vnymi z&aacute;kladmi: V prevažnej miere
                    sprac&uacute;vame va&scaron;e osobn&eacute; &uacute;daje, nakoľko sprac&uacute;vanie
                    osobn&yacute;ch &uacute;dajov je nevyhnutn&eacute; na plnenie zmluvy, ktor&uacute; ste
                    akcept&aacute;ciou na&scaron;ich podmienok použ&iacute;vania, s nami uzatvorili. Medzi ďal&scaron;ie
                    pr&aacute;vne z&aacute;klady patr&iacute; plnenie na&scaron;ich z&aacute;konn&yacute;ch
                    povinnost&iacute;, va&scaron;e a na&scaron;e opr&aacute;vnen&eacute; z&aacute;ujmy a
                    v&aacute;&scaron; s&uacute;hlas so sprac&uacute;van&iacute;m osobn&yacute;ch &uacute;dajov. Pokiaľ
                    sprac&uacute;vame va&scaron;e osobn&eacute; &uacute;daje na z&aacute;klade v&aacute;&scaron;ho
                    s&uacute;hlas, tento s&uacute;hlas ste opr&aacute;vnen&yacute; kedykoľvek odvolať.</p>
                <p style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Komu va&scaron;e osobn&eacute; &uacute;daje poskytujeme alebo spr&iacute;stupňujeme?
                </p>
                <p>Z hľadiska Z&aacute;kona m&aacute;me postavenie prev&aacute;dzkovateľa, čo znamen&aacute;, že
                    pr&aacute;ve na&scaron;a spoločnosť vymedzuje &uacute;čel spracovania. V s&uacute;vislosti s
                    na&scaron;ou činnosťou, m&ocirc;žu byť va&scaron;e osobn&eacute; &uacute;daje
                    pren&aacute;&scaron;an&eacute; alebo spr&iacute;stupňovan&eacute; ďal&scaron;&iacute;m subjektom, a
                    to
                    či už v postaven&iacute; samostatn&eacute;ho prev&aacute;dzkovateľa alebo sprostredkovateľa.
                    Vzhľadom na
                    to, že v r&aacute;mci na&scaron;ich služieb umožňujeme pre na&scaron;ich klientov&nbsp; inzerovať na
                    na&scaron;ich port&aacute;loch, pln&iacute;me aj &uacute;lohu sprostredkovateľa v zmysle
                    Z&aacute;kona.
                    Prostredn&iacute;ctvom kontaktn&eacute;ho formul&aacute;ra umiestnen&eacute;ho pri konkr&eacute;tnom
                    inzer&aacute;te, m&ocirc;žete kontaktovať priamo predajcu tovaru uveden&eacute;ho v
                    konkr&eacute;tnom
                    inzer&aacute;te a teda &uacute;daje zadan&eacute; do kontaktn&eacute;ho formul&aacute;ra
                    sprac&uacute;va
                    konkr&eacute;tny predajca ako prev&aacute;dzkovateľ a my ako jeho sprostredkovateľ. Ako
                    prev&aacute;dzkovateľ dohliadame na to, aby v&scaron;etci, ktor&yacute;m s&uacute; va&scaron;e
                    osobn&eacute; &uacute;daje pren&aacute;&scaron;an&eacute;, dodržiavali vysok&yacute; &scaron;tandard
                    ich
                    ochrany. V žiadnom pr&iacute;pade neposkytujeme va&scaron;e osobn&eacute; &uacute;daje subjektu, u
                    ktor&eacute;ho nie je tento &scaron;tandard zabezpečen&yacute;. Zoznam pr&iacute;jemcov: Facebook
                    Inc.,
                    1601 Willow Road, Menlo Park, CA 94025, United States Google LLC, 1600 Amphitheatre Parkway,
                    Mountain
                    View, CA 94043, United States Instagram LLC, ATTN: Arbitration Opt-out 1601 Willow Rd. Menlo Park,
                    CA
                    94025, United States ThePay, s.r.o. S&iacute;dlo: Masarykovo n&aacute;měst&iacute; 102/65, 586 01
                    Jihlava IČ: 2813526 YouTube LLC, 901 Cherry Avenue, San Bruno, CA 94066, United States Okruhy
                    pr&iacute;jemcov: Dod&aacute;vatelia program&aacute;torsk&yacute;ch služieb. Osoby, ktor&yacute;m
                    sme
                    povinn&iacute; poskytn&uacute;ť va&scaron;e &uacute;daje na z&aacute;klade z&aacute;kona, napr.
                    Slovensk&aacute; obchodn&aacute; in&scaron;pekcia, s&uacute;dy, org&aacute;ny činn&eacute; v
                    trestnom
                    konan&iacute;.</p>
                <p style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Ako dlho bud&uacute; va&scaron;e osobn&eacute; &uacute;daje sprac&uacute;van&eacute;?
                </p>
                <p>Sprac&uacute;vanie va&scaron;ich osobn&yacute;ch &uacute;dajov prebieha v s&uacute;lade so
                    z&aacute;konn&yacute;mi požiadavkami, čo znamen&aacute;, že osobn&eacute; &uacute;daje
                    neuchov&aacute;vame dlh&scaron;ie, ako je nevyhnutn&eacute; na &uacute;čel sprac&uacute;vania.
                    Pokiaľ
                    va&scaron;e osobn&eacute; &uacute;daje sprac&uacute;vame na pr&aacute;vnom
                    z&aacute;klade &ndash; plnenie zmluvy, tak va&scaron;e osobn&eacute; &uacute;daje sprac&uacute;vame
                    po
                    cel&uacute; dobu trvania zmluvn&eacute;ho vzťahu medzi nami. Ak d&ocirc;jde k ukončeniu zmluvy
                    pristupujeme k likvid&aacute;cii va&scaron;ich osobn&yacute;ch &uacute;dajov, pokiaľ nie je
                    možn&eacute; sprac&uacute;vať va&scaron;e osobn&eacute; &uacute;daje na inom pr&aacute;vnom
                    z&aacute;klade. Napr&iacute;klad vymažete v&aacute;&scaron; jedin&yacute; inzer&aacute;t, a my sme
                    povinn&iacute; prist&uacute;piť k likvid&aacute;cii va&scaron;ich osobn&yacute;ch &uacute;dajov. Ak
                    ste
                    v&scaron;ak počas využ&iacute;vania na&scaron;ich služieb použili i niektor&eacute; na&scaron;e
                    platen&eacute; služby (napr. topovanie inzer&aacute;tu), sme opr&aacute;vnen&iacute; naďalej
                    sprac&uacute;vať &uacute;daje t&yacute;kaj&uacute;ce sa va&scaron;ich realizovan&yacute;ch platieb
                    pre
                    potreby plnenia povinnost&iacute; vypl&yacute;vaj&uacute;cich pre n&aacute;s z daňov&yacute;ch
                    a &uacute;čtovn&yacute;ch pr&aacute;vnych predpisov. Ak va&scaron;e osobn&eacute; &uacute;daje
                    sprac&uacute;vame na pr&aacute;vnom z&aacute;klade &ndash; plnenie z&aacute;konn&yacute;ch
                    povinnost&iacute;, tak va&scaron;e osobn&eacute; &uacute;daje sprac&uacute;vame po dobu,
                    ktor&uacute; určuj&uacute; pr&aacute;vne predpisy. Pokiaľ va&scaron;e osobn&eacute; &uacute;daje
                    sprac&uacute;vame na pr&aacute;vnom
                    z&aacute;klade &ndash; n&aacute;&scaron; opr&aacute;vnen&yacute; z&aacute;ujem alebo
                    opr&aacute;vnen&yacute; z&aacute;ujem tret&iacute;ch os&ocirc;b, tak va&scaron;e
                    osobn&eacute; &uacute;daje sprac&uacute;vame po dobu trvania opr&aacute;vnen&eacute;ho
                    z&aacute;ujmu. V
                    pr&iacute;pade, ak va&scaron;e osobn&eacute; &uacute;daje sprac&uacute;vame na pr&aacute;vnom
                    z&aacute;klade &ndash; v&aacute;&scaron; s&uacute;hlas, tak va&scaron;e osobn&uacute; &uacute;daje
                    sprac&uacute;vame po dobu, na ktor&uacute; ste n&aacute;m s&uacute;hlas so
                    sprac&uacute;van&iacute; va&scaron;ich osobn&yacute;ch &uacute;dajov udelili. Pri
                    sprac&uacute;van&iacute; osobn&yacute;ch &uacute;dajov za &uacute;čelom &uacute;časti v nami
                    organizovanej s&uacute;ťaži na podporu na&scaron;ich produktov alebo služieb, je t&aacute;to doba
                    uveden&aacute; v konkr&eacute;tom &scaron;tat&uacute;te s&uacute;ťaže alebo podmienkach
                    s&uacute;ťaže.</p>
                <p style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Ak&eacute; m&aacute;te pr&aacute;va s&uacute;visiace s na&scaron;im sprac&uacute;van&iacute;m
                    va&scaron;ich osobn&yacute;ch &uacute;dajov?
                </p>
                <p>Z&aacute;kon ako aj Nariadenie v&aacute;m poskytuje viacer&eacute; pr&aacute;va,
                    ktor&eacute; v&aacute;m
                    umožňuj&uacute; to, aby ste mali ochranu svojich osobn&yacute;ch &uacute;dajov pod vlastnou
                    kontrolou.
                    Na&scaron;a spoločnosť re&scaron;pektuje va&scaron;e pr&aacute;va. V tejto sekcii sa preto dozviete,
                    ak&eacute; s&uacute;</p>
                <ul>
                    <li>Pr&aacute;vo na pr&iacute;stup k osobn&yacute;m &uacute;dajom. Toto pr&aacute;vo znamen&aacute;,
                        že
                        ste opr&aacute;vnen&iacute; žiadať od n&aacute;s potvrdenie o tom, či sa
                        spracov&aacute;vaj&uacute; o v&aacute;s osobn&eacute; &uacute;daje, ktor&eacute; sa v&aacute;s
                        t&yacute;kaj&uacute;. Ak teda tak&eacute;to osobn&eacute; &uacute;daje o v&aacute;s
                        sprac&uacute;vame m&aacute;te pr&aacute;vo z&iacute;skať pr&iacute;stup k svojim
                        osobn&yacute;m &uacute;dajom a inform&aacute;cie o tom: - prečo sprac&uacute;vame va&scaron;e
                        osobn&eacute; &uacute;daje (&uacute;čel spracovania osobn&yacute;ch &uacute;dajov) -
                        ak&eacute; &uacute;daje o v&aacute;s sprac&uacute;vame (kateg&oacute;ria
                        osobn&yacute;ch &uacute;dajov) - komu m&ocirc;žu resp. bud&uacute; va&scaron;e
                        osobn&eacute; &uacute;daje poskytnut&eacute; (identifik&aacute;cia pr&iacute;jemcov alebo okruhu
                        pr&iacute;jemcov) - ako dlho budeme va&scaron;e osobn&eacute; &uacute;daje uchov&aacute;vať
                        (doba
                        uchov&aacute;vania osobn&yacute;ch &uacute;dajov) - že m&aacute;te pr&aacute;vo požadovať od
                        n&aacute;s opravu osobn&yacute;ch &uacute;dajov, ich vymazanie ako aj obmedzenie ich spracovania
                        alebo že m&aacute;te možnosť namietať voči spracovaniu osobn&yacute;ch &uacute;dajov - že
                        m&aacute;te pr&aacute;vo podať n&aacute;vrh na začatie konania o ochrane
                        osobn&yacute;ch &uacute;dajov podľa Z&aacute;kona alebo pr&aacute;vo podať sťažnosť
                        dozorn&eacute;mu &uacute;radu podľa Nariadenia, ktor&yacute;m je &Uacute;rad na ochranu
                        osobn&yacute;ch &uacute;dajov SR - odkiaľ poch&aacute;dzaj&uacute; va&scaron;e
                        osobn&eacute; &uacute;daje (inform&aacute;cie o zdroji), pokiaľ ste n&aacute;m
                        osobn&eacute; &uacute;daje neposkytli priamo vy - či využ&iacute;vame
                        automatizovan&eacute; individu&aacute;lne rozhodovanie vr&aacute;tane profilovania
                        podľa &sect; 28
                        ods. 1 a 4 Z&aacute;kona; najm&auml; o použitom postupe, ako aj o v&yacute;zname a
                        predpokladan&yacute;ch d&ocirc;sledkoch tak&eacute;ho sprac&uacute;vania
                        osobn&yacute;ch &uacute;dajov pre v&aacute;s - o primeran&yacute;ch z&aacute;ruk&aacute;ch
                        t&yacute;kaj&uacute;cich sa prenosu, v pr&iacute;pade, ak va&scaron;e osobn&eacute; &uacute;daje
                        pren&aacute;&scaron;ame do tretej krajiny alebo medzin&aacute;rodnej organiz&aacute;cie Toto
                        pr&aacute;vo na pr&iacute;stup pre v&aacute;s znamen&aacute; i možnosť z&iacute;skať
                        osobn&eacute; &uacute;daje, ktor&eacute; o v&aacute;s sprac&uacute;vame. Poskytneme v&aacute;m
                        k&oacute;piu va&scaron;ich osobn&yacute;ch &uacute;dajov, ktor&eacute; o v&aacute;s
                        sprac&uacute;vame. Pokiaľ v&scaron;ak budete žiadať opakovane o poskytnutie svojich
                        osobn&yacute;ch &uacute;dajov, m&ocirc;žeme od v&aacute;s žiadať
                        primeran&yacute; administrat&iacute;vny poplatok v s&uacute;vislosti s va&scaron;ou žiadosťou o
                        k&oacute;piu svojich osobn&yacute;ch &uacute;dajov.
                    </li>
                    <li>Pr&aacute;vo na opravu osobn&yacute;ch &uacute;dajov. V r&aacute;mci podmienok poskytovania
                        na&scaron;ich služieb v&aacute;s informujeme, aby si n&aacute;m poskytoval
                        spr&aacute;vne &uacute;daje. Ak sa v&scaron;ak napriek tomu stane, že sprac&uacute;vame o
                        v&aacute;s
                        nespr&aacute;vne &uacute;daje, pr&aacute;vo na opravu osobn&yacute;ch &uacute;dajov
                        znamen&aacute;,
                        že ste opr&aacute;vnen&iacute; žiadať, aby sme opravili nespr&aacute;vne
                        osobn&eacute; &uacute;daje,
                        ktor&eacute; sa v&aacute;s t&yacute;kaj&uacute;. Taktiež so zreteľom na &uacute;čel spracovania
                        osobn&yacute;ch &uacute;daje m&aacute;te pr&aacute;vo aj na to, aby boli
                        doplnen&eacute; va&scaron;e
                        ne&uacute;pln&eacute; osobn&eacute; &uacute;daje.
                    </li>
                    <li>Pr&aacute;vo na v&yacute;maz osobn&yacute;ch &uacute;dajov (tzv. pr&aacute;vo na zabudnutie).
                        M&aacute;te pr&aacute;vo na to, aby sme vymazali osobn&eacute; &uacute;daje, ktor&eacute; sa
                        v&aacute;s t&yacute;kaj&uacute; a z&aacute;roveň je splnen&yacute; aspoň jeden z
                        nasledovn&yacute;ch
                        d&ocirc;vodov: - va&scaron;e osobn&eacute; &uacute;daje už nie
                        s&uacute; potrebn&eacute; na &uacute;čel, na ktor&yacute; sme ich z&iacute;skali alebo inak
                        sprac&uacute;vali - odvolali ste svoj s&uacute;hlas so spracovan&iacute;m
                        osobn&yacute;ch &uacute;dajov, na z&aacute;klade ktor&eacute;ho my sprac&uacute;vame va&scaron;e
                        osobn&eacute; &uacute;daje a z&aacute;roveň neexistuje in&yacute; pr&aacute;vny z&aacute;klad
                        pre
                        ich ďal&scaron;ie sprac&uacute;vanie - namietate voči sprac&uacute;vaniu
                        osobn&yacute;ch &uacute;dajov, ktor&eacute; je vykon&aacute;van&eacute; na pr&aacute;vnom
                        z&aacute;klade verejn&eacute;ho z&aacute;ujmu alebo opr&aacute;vnen&eacute;ho z&aacute;ujmu a
                        neprevažuj&uacute; žiadne opr&aacute;vnen&eacute; d&ocirc;vody na ich sprac&uacute;vanie -
                        va&scaron;e osobn&eacute; &uacute;daje sa sprac&uacute;vali nez&aacute;konne - va&scaron;e
                        osobn&eacute; &uacute;daje musia byť vymazan&eacute;, nakoľko je nutn&eacute; splniť povinnosť
                        podľa
                        pr&aacute;va Slovenskej republiky alebo pr&aacute;va Eur&oacute;pskej &uacute;nie - va&scaron;e
                        osobn&eacute; &uacute;daje sa z&iacute;skavali v s&uacute;vislosti s ponukou služieb informačnej
                        spoločnosti podľa &sect; 15 ods. 1 Z&aacute;kona V&aacute;&scaron;mu pr&aacute;vu na
                        v&yacute;maz
                        osobn&yacute;ch &uacute;dajov v&scaron;ak v konkr&eacute;tnom pr&iacute;padne s ohľadom na
                        konkr&eacute;tne okolnosti nemus&iacute; byť vyhoven&eacute;, pokiaľ je spracovanie
                        osobn&yacute;ch &uacute;dajov potrebn&eacute; na: - na uplatnenie n&aacute;&scaron;ho
                        pr&aacute;va
                        na slobodu prejavu alebo pr&aacute;va na inform&aacute;cie - na splnenie z&aacute;konnej
                        povinnosti
                        - na uplatnenie n&aacute;&scaron;ho pr&aacute;vneho n&aacute;roku - na &uacute;čel
                        archiv&aacute;cie, na &uacute;čel historick&eacute;ho v&yacute;skumu alebo
                        na &scaron;tatistick&yacute; &uacute;čel, pokiaľ je pravdepodobn&eacute;, že pr&aacute;vo na
                        v&yacute;maz by n&aacute;m znemožnilo alebo z&aacute;važn&yacute;m sp&ocirc;sobom sťažilo
                        dosiahnutie cieľov tak&eacute;ho sprac&uacute;vania
                    </li>
                    <li>Pr&aacute;vo na obmedzenie sprac&uacute;vania osobn&yacute;ch &uacute;dajov. M&aacute;te
                        pr&aacute;vo na to, aby sme obmedzili sprac&uacute;vanie osobn&yacute;ch &uacute;dajov,
                        ktor&eacute; sa v&aacute;s t&yacute;kaj&uacute; a z&aacute;roveň je splnen&yacute; aspoň jeden z
                        nasledovn&yacute;ch d&ocirc;vodov: - počas obdobia umožňuj&uacute;ceho n&aacute;m overenie
                        spr&aacute;vnosti va&scaron;ich osobn&yacute;ch &uacute;dajov, napadnete spr&aacute;vnosť
                        svojich
                        osobn&yacute;ch &uacute;dajov - p&ocirc;jde o nez&aacute;konn&eacute; sprac&uacute;vanie
                        osobn&yacute;ch &uacute;dajov, namietnete v&yacute;maz osobn&yacute;ch &uacute;dajov a budete
                        žiadať
                        namiesto v&yacute;mazu obmedzenie sprac&uacute;vania osobn&yacute;ch &uacute;dajov - my už
                        nebudeme
                        potrebovať va&scaron;e osobn&eacute; &uacute;daje na žiadny z &uacute;čelov sprac&uacute;vania,
                        alebo budete ich potrebovať vy na preuk&aacute;zanie, uplatňovanie alebo uplatňovanie svojich
                        pr&aacute;vnych n&aacute;rokov - budete namietať voči sprac&uacute;vaniu
                        osobn&yacute;ch &uacute;dajov a to až do doby overenia, či na&scaron;e
                        opr&aacute;vnen&eacute; z&aacute;ujmy prevažuj&uacute; nad va&scaron;imi
                        opr&aacute;vnen&yacute;mi
                        z&aacute;ujmami
                    </li>
                    <li>Pr&aacute;vo na prenosnosť osobn&yacute;ch &uacute;dajov. Ak je na&scaron;e pr&aacute;vo na
                        sprac&uacute;vanie va&scaron;ich osobn&yacute;ch &uacute;dajov založen&eacute; na va&scaron;om
                        s&uacute;hlase alebo na plnen&iacute; z&aacute;v&auml;zkov vypl&yacute;vaj&uacute;cich zo
                        zmluvy,
                        ktor&uacute; sme s vami uzatvorili, m&aacute;te pr&aacute;vo požiadať o prenos &uacute;dajov,
                        ktor&eacute; sa v&aacute;s t&yacute;kaj&uacute; a ktor&eacute; ste n&aacute;m poskytli,
                        in&eacute;mu
                        prev&aacute;dzkovateľovi osobn&yacute;ch &uacute;dajov.
                    </li>
                    <li>Pr&aacute;vo na odvolanie s&uacute;hlasu. Pokiaľ sprac&uacute;vame va&scaron;e
                        osobn&eacute; &uacute;daje na pr&aacute;vnom z&aacute;klade &ndash; s&uacute;hlas dotknutej
                        osoby,
                        m&aacute;te pr&aacute;vo kedykoľvek odvolať tento s&uacute;hlas a to aj pred uplynut&iacute;m
                        doby,
                        na ktor&uacute; bol tento s&uacute;hlas udelen&yacute;. Odvolanie s&uacute;hlasu
                        nem&aacute; vplyv
                        na z&aacute;konnosť sprac&uacute;vania vych&aacute;dzaj&uacute;ceho zo s&uacute;hlasu pred jeho
                        odvolan&iacute;m.
                    </li>
                    <li>Pr&aacute;vo na ne&uacute;činnosť automatizovan&eacute;ho individu&aacute;lneho rozhodovania
                        vr&aacute;tane profilovania. M&aacute;te pr&aacute;vo žiadať, aby
                        automatizovan&eacute; individu&aacute;lne rozhodovania vr&aacute;tane profilovania bolo voči
                        v&aacute;m ne&uacute;činn&eacute;. PR&Aacute;VO NAMIETAŤ Ak m&aacute;te na to d&ocirc;vody
                        t&yacute;kaj&uacute;ce sa va&scaron;ej konkr&eacute;tnej situ&aacute;cie, m&aacute;te
                        pr&aacute;vo
                        namietať proti sprac&uacute;vaniu va&scaron;ich osobn&yacute;ch &uacute;dajov, ak je
                        pr&aacute;vnym
                        z&aacute;kladom pre tak&eacute; sprac&uacute;vanie
                        n&aacute;&scaron; opr&aacute;vnen&yacute; z&aacute;ujem. Ak v&scaron;ak
                        n&aacute;&scaron; opr&aacute;vnen&yacute; z&aacute;ujem na sprac&uacute;vanie prevy&scaron;uje
                        nad
                        va&scaron;im osobn&yacute;m z&aacute;ujmom, m&ocirc;žeme pokračovať v
                        sprac&uacute;van&iacute; va&scaron;ich &uacute;dajov, a to aj napriek va&scaron;ej
                        n&aacute;mietke
                        voči sprac&uacute;vaniu. Taktiež sme opr&aacute;vnen&yacute; pokračovať v
                        spracovan&iacute; va&scaron;ich &uacute;dajov, v pr&iacute;pade ak je to potrebn&eacute; pre
                        preukazovanie, uplatňovanie alebo obhajovanie na&scaron;ich pr&aacute;vnych n&aacute;rokov.
                        Priamy
                        marketing (vr&aacute;tane anal&yacute;z vykon&aacute;van&yacute;ch na &uacute;čely priameho
                        marketingu): M&aacute;te možnosť vzniesť n&aacute;mietku voči sprac&uacute;vaniu va&scaron;ich
                        osobn&yacute;ch &uacute;dajov na &uacute;čely priameho marketingu. N&aacute;mietka sa
                        t&yacute;ka aj
                        anal&yacute;z osobn&yacute;ch &uacute;dajov (tzv. profilovanie), ktor&eacute; sa
                        vykon&aacute;vaj&uacute; v s&uacute;vislosti s priamym marketingom. Ak m&aacute;te
                        n&aacute;mietky
                        voči priamemu marketingu, prestaneme sprac&uacute;vať va&scaron;e osobn&eacute; &uacute;daje na
                        tento &uacute;čel, ako aj na &uacute;čel v&scaron;etk&yacute;ch typov akci&iacute; priameho
                        marketingu. Samozrejme, m&ocirc;žete jednoducho odmietnuť odoberanie spr&aacute;v a
                        osobn&yacute;ch
                        pon&uacute;k na konkr&eacute;tnych kan&aacute;loch. M&ocirc;žete od n&aacute;s napr&iacute;klad
                        dost&aacute;vať len ponuky e-mailom, ale nie prostredn&iacute;ctvom SMS spr&aacute;v. Kedykoľvek
                        m&ocirc;žete zmeniť svoje nastavenia na webe alebo v aplik&aacute;cii. Svoje nastavenia
                        m&ocirc;žete
                        zmeniť aj prostredn&iacute;ctvom e-mailu, SMS alebo ich kombin&aacute;ciou. Ak potrebujete pomoc
                        s
                        nastaveniami, kedykoľvek sa m&ocirc;žete obr&aacute;tiť na na&scaron;e služby
                        z&aacute;kazn&iacute;kom. Ak ako dotknut&aacute; osoba nem&aacute;te sp&ocirc;sobilosť na
                        pr&aacute;vne &uacute;kony v plnom rozsahu, va&scaron;e pr&aacute;va v zmysle Nariadenia a
                        Z&aacute;kona m&ocirc;že uplatniť v&aacute;&scaron; z&aacute;konn&yacute; z&aacute;stupca.
                        Pr&aacute;va dotknutej osoby, ktor&aacute; nežije m&ocirc;že v zmysle Nariadenia a Z&aacute;kona
                        uplatniť bl&iacute;zka osoba. Pokiaľ s&uacute; va&scaron;e žiadosti ako dotknutej osoby zjavne
                        neopodstatnen&eacute; alebo neprimeran&eacute;, najm&auml; pre ich opakuj&uacute;cu sa povahu,
                        m&ocirc;žeme požadovať primeran&yacute; poplatok zohľadňuj&uacute;ci administrat&iacute;vne
                        n&aacute;klady na poskytnutie inform&aacute;ci&iacute; alebo odmietnuť konať na z&aacute;klade
                        žiadosti.
                    </li>
                </ul>
                <p style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Ako n&aacute;s m&ocirc;žete kontaktovať?
                </p>
                <p>Ak m&aacute;te ak&eacute;koľvek ot&aacute;zky t&yacute;kaj&uacute;ce sa spracovania va&scaron;ich
                    osobn&yacute;ch &uacute;dajov, m&ocirc;žete n&aacute;s skontaktovať v s&uacute;vislosti so
                    sprac&uacute;van&iacute;m va&scaron;ich osobn&yacute;ch &uacute;dajov, po&scaron;lite pros&iacute;m
                    e-mail na info@drazimto.sk, alebo prostredn&iacute;ctvom po&scaron;ty na adresu RDO s.r.o., so
                    s&iacute;dlom Tov&aacute;rensk&aacute; 2590/5, 811 09 Bratislava-mestsk&aacute; časť
                    Star&eacute; Mesto.
                    Ak s na&scaron;ou odpoveďou nebudete spokojn&iacute;, alebo sa domnievate, že spracov&aacute;vame
                    va&scaron;e &uacute;daje nez&aacute;konne, m&ocirc;žete sa sťažovať na dozorn&yacute; org&aacute;n
                    ktor&yacute;m je &Uacute;rad na ochranu osobn&yacute;ch &uacute;dajov Slovenskej republiky.
                    Ďal&scaron;ie inform&aacute;cie o dozornom org&aacute;ne Slovenskej republiky a postupe
                    pod&aacute;vania
                    sťažnost&iacute; n&aacute;jdete na jeho ofici&aacute;lnom webovom s&iacute;dle:
                    www.dataprotection.gov.sk. Ohľadom sprac&uacute;vania osobn&yacute;ch &uacute;dajov m&ocirc;žete
                    kontaktovať aj na&scaron;u zodpovedn&uacute; osobu za dohľad nad ochranou
                    osobn&yacute;ch &uacute;dajov
                    (Data Protection Officer), e-mailov&aacute; adresa: info@drazimto.sk.</p>
            </div>
        </Container>
    )
}

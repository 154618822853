import * as React from 'react'
import { Action, ClientContext } from "react-fetching-library"
import { AuthContext } from "../../App"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid"
import PaginationProps from "../../types/pagination/PaginationProps"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import {Box, Container, IconButton, Pagination, Stack, Tooltip, Typography} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import Loading from "../../components/loader/Loading"
import OrderInterface from "../../types/Order/OrderInterface"
import PaidIcon from '@mui/icons-material/Paid'
import ErrorComponent from "../../components/error/ErrorComponent"
import PageSizeSelect from "../../components/pageSizeSelect/PageSizeSelect";

export default function OrdersProPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const [orders, setOrders] = React.useState<OrderInterface[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isError, setIsError] = React.useState<boolean>(false)
    const [page, setPage] = React.useState<number>(1)
    const [pageSize, setPageSize] = React.useState<number>(5)
    const [pagination, setPagination] = React.useState<PaginationProps>({
        pagination: [],
        total: 0,
        pagesCount: 0,
    })
    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCountState` from being undefined during the loading
    const [rowCountState, setRowCountState] = React.useState<number>(
        pagination.pagination.length > 0 ? pagination.pagination.length : 0,
    )
    React.useEffect(() => {
        setRowCountState(pagination.total)
    }, [pagination.total, setRowCountState])

    // context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)

    // navigate
    const navigate = useNavigate()
    // actions
    const getFaqItemsAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/order/byIsPro/getAll/' + pageSize + '/' + page),
        headers: {
            Accept: 'application/json'
        }
    }

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    React.useEffect(() => {
        getOrders()
    }, [page, pageSize])

    React.useEffect(() => {
        if (!authContext.userLogged || !authContext.userLogged.hasAccessToProOrders) {
            navigate('/')
        }
    }, [authContext.userLogged])

    const getOrders = async (): Promise<void> => {
        setIsLoading(true)
        const {payload, error} = await request(getFaqItemsAction, clientContext)
        if (error) {
            setIsError(true)
        } else {
            if (payload.success) {
                setOrders(payload.data.pagination)
                setPagination({
                    ...pagination,
                    pagesCount: payload.data.pagesCount === 0 ? 1 : payload.data.pagesCount,
                    total: payload.data.total,
                })
            }
        }
        setIsLoading(false)
    }

    const handleCreatePayment = (orderId: number) => {
        navigate(`/createPayment/byOrder/${orderId}`)
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: '#',
            width: 70,
            renderCell: (params) => params.value
        },
        {
            field: 'number',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Číslo objednávky',
            width: 200,
            renderCell: (params) => params.value
        },
        {
            field: 'package',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Balík',
            width: 200,
            renderCell: (params) => params.value
        },
        {
            field: 'createdAt',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Čas vytvorenia',
            width: 200,
            renderCell: (params) => params.value
        },
        {
            field: 'user',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Použivateľ',
            width: 200,
            renderCell: (params) => params.value
        },
        {
            field: 'advertisement',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Ponuka',
            width: 200,
            renderCell: (params) => params.value
        },
        {
            field: 'actions',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Akcie',
            sortable: false,
            width: 140,
            renderCell: (params) => params.value
        }
    ]

    const rows: any[] = []

    orders.forEach((order: OrderInterface) => {
        rows.push({
            id: order.id,
            number: order.number,
            package: order.package?.name,
            createdAt: dayjs(order.createdAt, 'DD.MM.YYYY').format('DD.MM.YYYY'),
            user: order.userFullName,
            advertisement: order.advertisementSubject,
            actions: (
                <React.Fragment>
                    <Tooltip title='Vytvoriť platbu pre objednávku'>
                        <IconButton aria-label='Vytvoriť platbu pre objednávku'
                                    onClick={() => handleCreatePayment(order.id)}>
                            <PaidIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            )
        })
    })


    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <Container sx={{mt: '5%', mb: '2%'}}>
                <Typography
                    variant='h5'
                    component='div'
                    style={{fontFamily: 'OpenSansBold', marginBottom: '2%'}}
                    className='text-primary'
                >
                    Objednávky PRO balíčkov&nbsp;
                    <Tooltip
                        title='V tejto častí môžte vidieť použivateľov, ktorý si objednali balíček PRO a vytvoriť im platbu na aktiváciu PRO balíčka'
                    >
                        <InfoIcon className='text-primary'/>
                    </Tooltip>
                </Typography>
                <DataGrid
                    rowCount={rowCountState}
                    disableRowSelectionOnClick={true}
                    disableColumnMenu={true}
                    columns={columns}
                    loading={isLoading}
                    paginationMode='server'
                    pageSizeOptions={[5, 10, 25]}
                    rows={rows}
                    disableColumnSelector
                    disableDensitySelector
                    hideFooter
                    hideFooterPagination
                    hideFooterSelectedRowCount={true}
                    density={"standard"}
                    slots={{
                        toolbar: GridToolbarQuickFilter,
                        loadingOverlay: Loading,
                    }}
                />
                <Stack direction="row" pt={2}>
                    <Box flex={1}>
                        {orders && (
                            <Typography variant="subtitle2">
                                Počet najdených záznamov: {pagination.total}
                            </Typography>
                        )}
                    </Box>
                    <Pagination
                        count={pagination.pagesCount}
                        page={page}
                        siblingCount={3}
                        onChange={(e, value) => setPage(value)}
                    />
                    <Box flex={1}/>
                    <PageSizeSelect
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                    />
                </Stack>
            </Container>
        </React.Fragment>
    )
}

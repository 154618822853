import * as React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { AdvertisementInterface } from '../../types/Advertisement/AdvertisementInterface'
import { Action, ClientContext } from "react-fetching-library"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import marker_icon from '../../assets/icons/marker-icon.png'
import L from 'leaflet'
import Loading from "../loader/Loading"
import ErrorComponent from "../error/ErrorComponent"
import 'leaflet/dist/leaflet.css'

interface LeafletMapProps {
    advertisementId: number
}

const defaultMarkerIcon = new L.Icon({
    iconUrl: marker_icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
})

export default function LeafletMap(props: LeafletMapProps): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE_WHEN_LOADING_MAP: string = 'Nastala neočakavaná chyba pri načítavaní mapy!'
    //- state
    const [position, setPosition] = React.useState<[number, number]>([0, 0])
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isError, setIsError] = React.useState<boolean>(false)
    const [advertisement, setAdvertisement] = React.useState<AdvertisementInterface | null>(null)
    //- context
    const clientContext = React.useContext(ClientContext)

    //- action
    const getAdvertisementLocationAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl(`/api/locations/geo-location/${props.advertisementId}`),
        headers: {
            Accept: 'application/json'
        }
    }

    const getAdvertisementAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl(`/api/advertisement/${props.advertisementId}`),
        headers: {
            Accept: 'application/json'
        }
    }

    React.useEffect(() => {
        const getAdvertisement = async () => {
            setIsLoading(true)
            const {payload, error, status} = await request(getAdvertisementAction, clientContext)
            if (error) {
                setIsError(true)
                setIsLoading(false)
            } else {
                if (status === 200) {
                    setAdvertisement(payload)
                }
            }
        }
        getAdvertisement()
    }, [props.advertisementId])

    React.useEffect(() => {
        const getAdvertisementLocation = async () => {
            const {payload, error} = await request(getAdvertisementLocationAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (payload && !isError) {
                    if (isNaN(parseFloat(payload.lat)) || isNaN(parseFloat(payload.lon))) {
                        setIsError(true)
                        setIsLoading(false)
                        return
                    }
                    setPosition([parseFloat(payload.lat), parseFloat(payload.lon)])
                } else {
                    setIsError(true)
                }
            }
            setIsLoading(false)
        }
        getAdvertisementLocation()
    }, [props.advertisementId])

    const getMapNameByAdvertisement = (advertisement: AdvertisementInterface): string => {
        let name = ''
        if (advertisement?.city) {
            name += `${advertisement.city.name},`
        }
        if (advertisement?.address) {
            name += ` ${advertisement.address}`
            if (!advertisement?.city) {
                name += ','
            }
        }
        if (advertisement?.houseNumber) {
            name += ` ${advertisement.houseNumber},`
        }
        name += ' Slovensko'
        return name
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE_WHEN_LOADING_MAP}
            />
        )
    }

    return (
        <MapContainer style={{height: '350px', marginTop: '25px', marginBottom: '25px'}} className='rounded-borders'
                      zoom={13} center={position}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={defaultMarkerIcon}>
                <Popup>
                    {getMapNameByAdvertisement(advertisement!)}
                </Popup>
            </Marker>
        </MapContainer>
    )
}

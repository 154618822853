import * as React from 'react'
import { Action, ClientContext } from 'react-fetching-library'
import { useNavigate, useParams } from 'react-router-dom'
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import AddOrEditAdvertisement from "../../components/advertisement/AddOrEditAdvertisement"
import { AuthContext } from "../../App"
import { Container, Typography } from "@mui/material"
import Loading from '../../components/loader/Loading'
import ErrorComponent from "../../components/error/ErrorComponent"

export default function EditAdvertisementPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const EDITED_ADVERTISEMENT_MESSAGE: string = 'Inzerát bol upravený!'
    const COULDNT_EDIT_ADVERTISEMENT_MESSAGE: string = 'Niektoré údaje neboli vyplnené alebo niesu správne vyplnené!'
    // state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [canAddDocuments, setCanAddDocuments] = React.useState<boolean>(false)
    const [canAddPhotographs, setCanAddPhotographs] = React.useState<boolean>(false)
    const [canAddYoutubeVideoEmbed, setCanAddYoutubeVideoEmbed] = React.useState<boolean>(false)
    const [isError, setIsError] = React.useState<boolean>(false)
    // context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)
    // params
    const params = useParams()
    const {id} = params
    const jwtToken = authContext.jwtToken
    const navigate = useNavigate()

    const editAdvertisementAction: Action = {
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/advertisement/edit/${id}`),
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            Accept: 'application/json',
        },
    }

    const checkRightsAction: Action = {
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/check/user/right/`), // '3' = documents
        method: 'GET',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            Accept: 'application/json',
        },
    }

    const checkRightsToYoutubeVideoEmbedAction: Action = {
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/check_user_access_by_feature_type/youtube_video_embed`),
        method: 'GET',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            Accept: 'application/json',
        },
    }

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    React.useEffect(() => {
        const canAddDocumentsRight = async (): Promise<void> => {
            const checkRightsAction2 = {
                ...checkRightsAction,
                endpoint: checkRightsAction.endpoint + `3/${id}`
            }
            const {payload, status, error} = await request(checkRightsAction2, clientContext)
            if (error) {
                setIsError(true)
            }
            if (status === 401) {
                authContext.logout()
            }
            if (status === 200) {
                setCanAddDocuments(payload.hasAccess)
            }
        }
        const checkCanAddPhotographsRight = async (): Promise<void> => {
            const checkRightsAction2 = {
                ...checkRightsAction,
                endpoint: checkRightsAction.endpoint + `1/${id}`
            }
            const {payload, status, error} = await request(checkRightsAction2, clientContext)
            if (error) {
                setIsError(true)
            }
            if (status === 401) {
                authContext.logout()
            }
            if (status === 200) {
                setCanAddPhotographs(payload.hasAccess)
            }
            setIsLoading(false)
        }

        const checkCanAddYoutubeVideoEmbedRight = async (): Promise<void> => {
            const {payload, error, status} = await request(checkRightsToYoutubeVideoEmbedAction, clientContext)
            if (error) {
                setIsError(true)
            }
            if (status === 401) {
                authContext.logout()
            }
            if (status === 200) {
                setCanAddYoutubeVideoEmbed(payload.hasAccess)
            }
            setIsLoading(false)
        }
        checkCanAddYoutubeVideoEmbedRight()
        canAddDocumentsRight()
        checkCanAddPhotographsRight()
    }, [])

    // loading
    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            <Container sx={{marginTop: '3%'}}>
                <Typography variant='h6' component='p' style={{fontFamily: 'OpenSansLight'}}>
                    Úprava inzerátu
                </Typography>
                <AddOrEditAdvertisement
                    isAdd={false}
                    canAddDocuments={canAddDocuments}
                    canAddPhotographs={canAddPhotographs}
                    canAddYoutubeVideoEmbed={canAddYoutubeVideoEmbed}
                    action={editAdvertisementAction}
                    advertisement={null}
                    advertisementId={parseInt(id as string)}
                    messageOnError={COULDNT_EDIT_ADVERTISEMENT_MESSAGE}
                    messageOnSuccess={EDITED_ADVERTISEMENT_MESSAGE}

                />
            </Container>
        </React.Fragment>
    )
}
import { Action, ClientContext } from "react-fetching-library"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import * as React from 'react'
import FaqItemInterface from "../../types/FaqItem/FaqItemInterface"
import Container from "@mui/material/Container"
import FaqItemCard from '../../components/faqItem/FaqItemCard'
import Loading from "../../components/loader/Loading"
import ErrorComponent from "../../components/error/ErrorComponent"

export default function FaqPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    // state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [faqItems, setFaqItems] = React.useState<FaqItemInterface[]>([])
    const [isError, setIsError] = React.useState<boolean>(false)
    // context
    const clientContext = React.useContext(ClientContext)

    const getFaqItemsAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/faq/item/getAll/notPaginated'),
        headers: {
            Accept: 'application/json'
        }
    }

    React.useEffect(() => {
        const getFaqItems = async (): Promise<void> => {
            const {payload, error} = await request(getFaqItemsAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                setFaqItems(payload)
            }
            setIsLoading(false)
        }
        getFaqItems()
    }, [])

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            <Container style={{marginTop: '3%'}}>
                <h1 className='text-primary'>
                    FAQ
                </h1>
                {!isLoading && faqItems.length > 0 && (
                    <div style={{marginTop: '3%'}}>
                        {faqItems.map((faqItem: FaqItemInterface, index: number) => (
                            <div style={{marginBottom: '15px'}} key={faqItem.id}>
                                <FaqItemCard
                                    key={faqItem.id}
                                    faqItem={faqItem}
                                />
                            </div>
                        ))}
                    </div>
                )}
                {!isLoading && faqItems.length === 0 && (
                    <React.Fragment>
                        <p className='text-primary'>
                            Žiadne položky
                        </p>
                    </React.Fragment>
                )}
            </Container>
        </React.Fragment>
    )
}

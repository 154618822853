import * as React from 'react'
import { AuthContext } from "../../App"
import { Action, ClientContext } from "react-fetching-library"
import UserLoggedInterface from "../../types/User/UserLoggedInterface"
import { toast } from "react-toastify"
import { useNavigate } from 'react-router-dom'
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import { Box, Card, CardContent, Container, Grid, Tooltip, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from "@mui/icons-material"
import Loading from "../../components/loader/Loading"
import ErrorComponent from "../../components/error/ErrorComponent"

export default function ChangePasswordPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const PASSWORD_CHANGED_MESSAGE: string = 'Heslo bolo úspešne zmenené'
    const PASSWORD_WASNT_CHANGED_ERROR_MESSAGE: string = 'Nepodarilo sa zmeniť heslo'
    const authContext = React.useContext(AuthContext)
    const [userLogged] = React.useState<UserLoggedInterface | null>(authContext.userLogged) // tu uz by mal mat context nacitaneho usera v state
    const [oldPassword, setOldPassword] = React.useState<string | null>(null)
    const [isOldPasswordValid, setIsOldPasswordValid] = React.useState<boolean>(true) // defaultne je null validne
    const [password, setPassword] = React.useState<string | null>(null)
    const [passwordRepeat, setPasswordRepeat] = React.useState<string | null>(null)
    const [isPasswordValid, setIsPasswordValid] = React.useState<boolean>(true) // defaultne je null validne
    const [isPasswordMatched, setIsPasswordMatched] = React.useState<boolean>(true)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [passwordVisible, setPasswordVisible] = React.useState(false)
    const [passwordRepeatVisible, setPasswordRepeatVisible] = React.useState(false)
    const [passwordOldVisible, setPasswordOldVisible] = React.useState(false)
    const [isError, setIsError] = React.useState<boolean>(false)
    //- init navigate
    const navigate = useNavigate()
    // context
    const clientContext = React.useContext(ClientContext)
    const changePasswordAction: Action = {
        method: 'PUT',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/change-password'),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json'
        },
        body: {
            userId: userLogged?.id,
            oldPassword: oldPassword,
            plainPassword: password
        }
    }

    const validateOldPassword = (password: string | null): boolean => {
        if (password === null) {
            setIsOldPasswordValid(false)
            return false
        }
        setIsOldPasswordValid(true)
        return true
    }

    const togglePasswordOldVisibility = (): void => {
        setPasswordOldVisible(!passwordOldVisible)
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }

    const togglePasswordRepeatVisibility = (): void => {
        setPasswordRepeatVisible(!passwordRepeatVisible)
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'oldPassword') {
            setOldPassword(event.target.value)
            validateOldPassword(event.target.value)
        } else if (event.target.name === 'password') {
            setPassword(event.target.value)
            validatePasswordRegex(event.target.value)
        } else if (event.target.name === 'passwordRepeat') {
            setPasswordRepeat(event.target.value)
            validatePasswordMatch(password, event.target.value)
        }
    }

    const validatePasswordRegex = (password: string | null): void => {
        const passwordValidationRegex: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/i
        if (password === null) {
            setIsPasswordValid(true) // null je validne
        } else {
            setIsPasswordValid(passwordValidationRegex.test(password))
        }
    }

    const validatePasswordMatch = (password: string | null, passwordRepeat: string | null): void => {
        setIsPasswordMatched(password === passwordRepeat)
    }


    // submit
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        if ((!isPasswordValid || !isPasswordMatched) || !validateOldPassword(oldPassword)) {
            validatePasswordRegex(password)
            validatePasswordMatch(password, passwordRepeat)
            toast.error(PASSWORD_WASNT_CHANGED_ERROR_MESSAGE)
            return
        }
        setIsLoading(true)
        const {payload, error, status} = await request(changePasswordAction, clientContext)
        if (error) {
            setIsError(true)
        }
        if (status === 401) {
            authContext.logout()
        }
        if (status === 201 || status === 200) {
            navigate('/')
            toast.success(PASSWORD_CHANGED_MESSAGE)
        } else if (payload.success === false) {
            const errors = payload.errors
            const keys = Object.keys(errors)
            if (keys.indexOf('oldPassword') !== -1) {
                toast.error(errors['oldPassword'])
            } else if (keys.indexOf('user')) {
                toast.error(errors['user'])
            } else if (keys.indexOf('passwordPlain') !== -1) {
                setIsPasswordValid(false)
                toast.error(PASSWORD_WASNT_CHANGED_ERROR_MESSAGE)
            }
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <Container sx={{mt: '4%', mb: '3%'}}>
            <Box display='flex' alignItems='center' justifyContent='center'>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px'}}>
                        <div className="w-100 text-center">
                            <Typography variant='h6' component='div' style={{fontFamily: 'OpenSansBold'}}
                                        className='text-primary'>
                                Zmena hesla
                            </Typography>
                        </div>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className="form-group">
                                        <label
                                            htmlFor="oldPassword"
                                            className='required text-primary'
                                            style={{fontFamily: 'OpenSansBold'}}
                                        >
                                            Staré heslo
                                        </label>
                                        <div className='password-input-wrapper'>
                                            <div className='input-container'>
                                                <input
                                                    type={passwordOldVisible ? 'text' : 'password'}
                                                    name="oldPassword"
                                                    id="oldPassword"
                                                    className='form-control border-primary rounded-borders'
                                                    value={oldPassword ?? ''}
                                                    onChange={onChange}
                                                />
                                                <div className='password-toggle-icon'
                                                     onClick={togglePasswordOldVisibility}>
                                                    {passwordOldVisible ? (
                                                        <Tooltip title='Skryť heslo'>
                                                            <VisibilityOff className='icon'/>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title='Zobraziť heslo'>
                                                            <Visibility className='icon'/>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {!isOldPasswordValid ? (
                                            <p className='error'>
                                                Hodnota nie je platné heslo. Heslo musí obsahovať aspoň 10 znakov, malé
                                                a veľké písmená,
                                                číslo a špeciálny znak
                                            </p>
                                        ) : null}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className="form-group">
                                        <label
                                            htmlFor="password"
                                            className='required text-primary'
                                            style={{fontFamily: 'OpenSansBold'}}
                                        >
                                            Heslo
                                        </label>
                                        <div className='password-input-wrapper'>
                                            <div className='input-container'>
                                                <input
                                                    type={passwordVisible ? 'text' : 'password'}
                                                    name="password"
                                                    id="password"
                                                    className='form-control border-primary rounded-borders'
                                                    value={password ?? ''}
                                                    onChange={onChange}
                                                />
                                                <div className='password-toggle-icon'
                                                     onClick={togglePasswordVisibility}>
                                                    {passwordVisible ? (
                                                        <Tooltip title='Skryť heslo'>
                                                            <VisibilityOff className='icon'/>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title='Zobraziť heslo'>
                                                            <Visibility className='icon'/>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {!isPasswordValid ? (
                                                <p className='error'>
                                                    Hodnota nie je platné heslo. Heslo musí obsahovať aspoň 10 znakov,
                                                    malé a veľké písmená,
                                                    číslo a špeciálny znak
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className="form-group">
                                        <label
                                            htmlFor="passwordRepeat"
                                            className='required text-primary'
                                            style={{fontFamily: 'OpenSansBold'}}
                                        >
                                            Heslo znova
                                        </label>
                                        <div className='password-input-wrapper'>
                                            <div className='input-container'>
                                                <input
                                                    type={passwordRepeatVisible ? 'text' : 'password'}
                                                    name="passwordRepeat"
                                                    id="passwordRepeat"
                                                    className='form-control border-primary rounded-borders'
                                                    value={passwordRepeat ?? ''}
                                                    onChange={onChange}
                                                />
                                                <div className='password-toggle-icon'
                                                     onClick={togglePasswordRepeatVisibility}>
                                                    {passwordRepeatVisible ? (
                                                        <Tooltip title='Skryť heslo'>
                                                            <VisibilityOff className='icon'/>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title='Zobraziť heslo'>
                                                            <Visibility className='icon'/>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {!isPasswordMatched ? (
                                                <p className='error'>
                                                    Heslá sa nezhodujú!
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className="form-group w-100 text-center">
                                        <button
                                            type="submit"
                                            className="background-primary rounded-borders border-primary"
                                            style={{fontFamily: 'OpenSansBold', fontSize: '18px', padding: '10px'}}
                                        >
                                            Resetovať heslo
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    )
}

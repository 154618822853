import * as React from 'react'
import { Action, ClientContext } from 'react-fetching-library'
import { AdvertisementFilterContext } from '../../App'
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material'
import { AdvertisementCategoryInterface } from "../../types/AdvertisementCategoryInterface/AdvertisementCategoryInterface"
import { DistrictInterface } from "../../types/District/DistrictInterface"
import { ArrowDropDown } from "@mui/icons-material"
import SearchIcon from '@mui/icons-material/Search'
import { AdvertisementFilterInterface } from "../../types/AdvertisementFilter/AdvertisementFilterInterface"
import { AdvertisementFilterContextType } from "../../types/AdvertisementFilterContext/AdvertisementFilterContextType"
import ToppedAdvertisements from "../../components/toppedAdvertisement/ToppedAdvertisements"
import { AdvertisementInterface } from "../../types/Advertisement/AdvertisementInterface"
import ShowAdvertisementPageList from "../../components/advertisement/ShowAdvertisementPageList"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import IconButton from "@mui/material/IconButton"
import Loading from '../../components/loader/Loading'
import ErrorComponent from "../../components/error/ErrorComponent"
import auta_kategoria from '../../assets/icons/auta_kategoria.svg'
import nehnutelne_veci from '../../assets/icons/nehnutelne_veci.svg'
import auta_kategoria_biele from '../../assets/icons/auta_kategoria_biele.svg'
import nehnutelne_veci_biele from '../../assets/icons/nehnutelne_veci_biele.svg'
import iny_majetok_biele from '../../assets/icons/iny_majetok_biele.png'
import iny_majetok_modre from '../../assets/icons/iny_majetok_modre.png'

enum AdvertisementThingType {
    MOVABLE = 1,
    IMMOVABLE = 2,
    OTHER = 3,
}

export default function ShowAdvertisementsPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    // context
    const filterContext: AdvertisementFilterContextType = React.useContext(AdvertisementFilterContext)
    const clientContext = React.useContext(ClientContext)
    //- filter
    const filterFromContext: AdvertisementFilterInterface = filterContext.getFilterFromSessionStorage()
    //- thingType, selectRefs
    const advertisementThingType = AdvertisementThingType
    const selectRefs = React.useRef<(HTMLSelectElement | null)[]>([])
    const selectContainers = React.useRef<(HTMLDivElement | null)[]>([])
    //- state
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [advertisementThingTypeSelected, setAdvertisementThingTypeSelected] = React.useState<AdvertisementThingType>(advertisementThingType.IMMOVABLE)
    const [categories, setCategories] = React.useState<AdvertisementCategoryInterface[]>([])
    const [districtIdSelected, setDistrictIdSelected] = React.useState<number>(filterFromContext.districtIds[0] || 0)
    const [selectedCategoryId, setSelectedCategoryId] = React.useState<number>(filterFromContext.categoryIds[0]  || 0)
    const [districts, setDistricts] = React.useState<DistrictInterface[]>([])
    const [priceFrom, setPriceFrom] = React.useState<number>(filterFromContext.priceFrom)
    const [priceTo, setPriceTo] = React.useState<number>(filterFromContext.priceTo)
    const [sortBy, setSortBy] = React.useState<string | null>(filterFromContext.sortBy)
    const [advertisements, setAdvertisements] = React.useState<AdvertisementInterface[]>([])
    const [limit, setLimit] = React.useState<number>(10) // limit zatial nemenime
    const [lastFetched, setLastFetched] = React.useState<number>(0)
    const [page, setPage] = React.useState<number>(1)
    const [total, setTotal] = React.useState<number>(0)
    const [isError, setIsError] = React.useState<boolean>(false)

    //- actions
    const getCategoriesAction: Action = {
        endpoint: getApiUrlFromRelativeUrl('/api/advertisement/category/get/all/notPaginated/'),
        method: 'GET',
        headers: {
            Accept: 'application/json'
        },
    }

    const getAllLocationsAction: Action = {
        endpoint: getApiUrlFromRelativeUrl('/api/locations/all'),
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    }

    // actions
    const getAdvertisementsAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl(`/api/advertisements/all/filter/paginated`),
        body: {
            thingType: advertisementThingTypeSelected,
            categoryIds: filterFromContext.categoryIds,
            cityIds: filterFromContext.cityIds,
            districtIds: filterFromContext.districtIds,
            priceFrom: filterFromContext.priceFrom === 0 || filterFromContext.priceFrom === null ? null : {
                price: filterFromContext.priceFrom,
                currency: '€'
            },
            priceTo: filterFromContext.priceTo === 0 || filterFromContext.priceTo === null ? null : {
                price: filterFromContext.priceTo,
                currency: '€'
            },
            sortBy: sortBy,
            page: page,
            limit: limit,
        },
        headers: {
            Accept: 'application/json'
        },
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getCategories = async (thingType: number, isRecalculate: boolean = false) => {
        setIsLoading(true)
        const getCategoriesAction2: Action = {
            ...getCategoriesAction,
            endpoint: getCategoriesAction.endpoint + thingType
        }
        const {error, payload} = await request(getCategoriesAction2, clientContext)
        if (error) {
            setIsError(true)
        }
        setCategories(payload)
        if (isRecalculate) {
            setSelectedCategoryId(0) // vycistime vybranu kategoriu - lebo advertisementThingTypeSelected sa zmenil
        }
        setIsLoading(false)
    }

    //- effect - get all categories - recalc
    React.useEffect(() => {
        getCategories(advertisementThingTypeSelected)
    }, [])

    React.useEffect(() => {
        handleSearch()
    }, [advertisementThingTypeSelected])

    const getAdvertisements = async (lastFetched: number, limit: number = 10, append: boolean = true): Promise<void> => {
        setIsLoading(true)
        const action2 = {
            ...getAdvertisementsAction,
            endpoint: `${getAdvertisementsAction.endpoint}`
        }
        const {error, payload} = await request(action2, clientContext)
        if (error) {
            setIsError(true)
        }
        if (payload.success) {
            let advertisements1: AdvertisementInterface[]
            if (append) {
                advertisements1 = [...advertisements, ...payload.data.pagination]
            } else {
                advertisements1 = [...payload.data.pagination]
            }
            //- set state
            setAdvertisements(advertisements1)
            setLastFetched(payload.data.lastFetched)
            setTotal(payload.data.total)
        }
        setIsLoading(false)
    }

    React.useEffect(() => {
        getAdvertisements(0, limit, false)
    }, [filterContext.filter])

    React.useEffect(() => {
        getAdvertisements(0, limit, true)
    }, [page])

    //- effect - get all locations(districts)
    React.useEffect(() => {
        const getLocations = async () => {
            const {error, payload} = await request(getAllLocationsAction, clientContext)
            if (error) {
                setIsError(true)
            }
            setDistricts(payload.districts)
        }
        getLocations()
    }, [])


    //- handlers
    const handleSearch = (): void => {
        //- vytvorime filter
        const filter = {
            thingType: advertisementThingTypeSelected,
            categoryIds: selectedCategoryId === 0 || selectedCategoryId === null ? [] : [selectedCategoryId],
            districtIds: districtIdSelected === 0 || districtIdSelected === null ? [] : [districtIdSelected],
            priceFrom: priceFrom,
            priceTo: priceTo,
            cityIds: [],
            sortBy: sortBy,
        }
        //-- set to localStorage
        filterContext.setFilterToStateAndSessionStorage(filter)
        setPage(1)
        /**
         * Bolo tu 'window.location.reload()', ale netreba nam to lebo mame hore effect, ktory pocuva na filterContext
         * Presnejsie: na zmenu filtra v contexte a teda natiahne nove advertisements podla filtra z contextu
         */
        window.scrollTo(0, 0)
    }

    const handleSelectContainerClick = (index: number) => {
        if (selectRefs.current[index]) {
            // Simulate a click event on the select element to open the dropdown
            const clickEvent = new MouseEvent('mousedown', {bubbles: true})
            selectRefs.current[index]!.dispatchEvent(clickEvent)
        }
    }


    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    //- render
    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <Container sx={{mt: '5%'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none', position: 'sticky', top: '50px', zIndex: 6}}>
                            <CardContent className='grey-background'
                                         style={{borderRadius: '15px'}}>
                                <Container>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Box
                                                className={`rounded-borders clickable ${advertisementThingTypeSelected === advertisementThingType.IMMOVABLE ? 'background-primary' : ''}`}
                                                onClick={() => {
                                                    setAdvertisementThingTypeSelected(advertisementThingType.IMMOVABLE)
                                                    getCategories(advertisementThingType.IMMOVABLE, true)
                                                }}
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='space-between'
                                                padding="10px"
                                                style={advertisementThingTypeSelected !== advertisementThingType.IMMOVABLE ? {backgroundColor: 'white'} : {}}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        src={advertisementThingTypeSelected === advertisementThingType.IMMOVABLE ? nehnutelne_veci_biele : nehnutelne_veci}
                                                        alt='Nehntueľnosti'
                                                        height='35px'
                                                        style={advertisementThingTypeSelected !== advertisementThingType.IMMOVABLE ? {marginRight: '10px'} : {
                                                            marginRight: '10px',
                                                            color: 'white'
                                                        }}
                                                    />
                                                    <Typography
                                                        variant='h6'
                                                        component='div'
                                                        sx={{sm: {fontSize: '16px'}}}
                                                        style={{fontFamily: 'OpenSansSemiBold', maxWidth: '70%', fontSize: '16px'}}
                                                        className={`${advertisementThingTypeSelected !== advertisementThingType.IMMOVABLE ? 'text-primary' : ''}`}
                                                    >
                                                        Nehnuteľnosti
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box
                                                className={`w-100 rounded-borders clickable ${advertisementThingTypeSelected === advertisementThingType.MOVABLE ? 'background-primary' : ''}`}
                                                onClick={() => {
                                                    setAdvertisementThingTypeSelected(advertisementThingType.MOVABLE)
                                                    getCategories(advertisementThingType.MOVABLE, true)
                                                }}
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='space-between'
                                                padding="10px"
                                                style={advertisementThingTypeSelected !== advertisementThingType.MOVABLE ? {backgroundColor: 'white'} : {}}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        src={advertisementThingTypeSelected === advertisementThingType.MOVABLE ? auta_kategoria_biele : auta_kategoria}
                                                        alt='Hnuteľnosti'
                                                        height='35px'
                                                        style={advertisementThingTypeSelected === advertisementThingType.MOVABLE ? {
                                                                color: 'white',
                                                                marginRight: '10px'
                                                            }
                                                            : {marginRight: '10px'}
                                                        }
                                                    />
                                                    <Typography
                                                        variant='h6'
                                                        component='div'
                                                        sx={{sm: {fontSize: '16px'}}}
                                                        style={{fontFamily: 'OpenSansSemiBold', maxWidth: '70%', fontSize: '16px'}}
                                                        className={`${advertisementThingTypeSelected !== advertisementThingType.MOVABLE ? 'text-primary' : ''}`}
                                                    >
                                                        Hnuteľnosti
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box
                                                className={`w-100 rounded-borders clickable ${advertisementThingTypeSelected === advertisementThingType.OTHER ? 'background-primary' : ''}`}
                                                onClick={() => {
                                                    setAdvertisementThingTypeSelected(advertisementThingType.OTHER)
                                                    getCategories(advertisementThingType.OTHER, true)
                                                }}
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='space-between'
                                                padding="10px"
                                                style={advertisementThingTypeSelected !== advertisementThingType.OTHER ? {backgroundColor: 'white'} : {}}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        src={advertisementThingTypeSelected === advertisementThingType.OTHER ? iny_majetok_biele : iny_majetok_modre}
                                                        alt='Iný majetok'
                                                        height='35px'
                                                        style={advertisementThingTypeSelected === advertisementThingType.OTHER ? {
                                                                color: 'white',
                                                                marginRight: '10px'
                                                            }
                                                            : {marginRight: '10px'}
                                                        }
                                                    />
                                                    <Typography
                                                        variant='h6'
                                                        component='div'
                                                        sx={{sm: {fontSize: '16px'}}}
                                                        style={{fontFamily: 'OpenSansSemiBold',  fontSize: '16px'}}
                                                        className={`${advertisementThingTypeSelected !== advertisementThingType.OTHER ? 'text-primary' : ''}`}
                                                    >
                                                        Iný majetok
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <div className="w-100">
                                                <div className="form-group w-100">
                                                    <label htmlFor="district">
                                                        Lokalita
                                                    </label>
                                                    <div className='select-container'
                                                         ref={(el) => (selectContainers.current[0] = el)}
                                                         onClick={() => handleSelectContainerClick(0)}
                                                    >
                                                        <select name="district"
                                                                id="district"
                                                                className='form-control rounded-borders clickable'
                                                                value={districtIdSelected}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setDistrictIdSelected(parseInt(e.target.value))}
                                                                ref={(el) => (selectRefs.current[0] = el)}
                                                        >
                                                            <option value="0"></option>
                                                            {districts.map((district: DistrictInterface) => (
                                                                <option value={district.id} key={district.id}>
                                                                    {district.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <ArrowDropDown className='arrow-icon'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <div className="w-100">
                                                <div className="form-group">
                                                    <label htmlFor="category">
                                                        Druh
                                                    </label>
                                                    <div className='select-container'
                                                         ref={(el) => (selectContainers.current[1] = el)}
                                                         onClick={() => handleSelectContainerClick(1)}
                                                    >
                                                        <select
                                                            name="category"
                                                            id="category"
                                                            className='form-control rounded-borders clickable'
                                                            value={selectedCategoryId}
                                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedCategoryId(parseInt(e.target.value))}
                                                            ref={(el) => (selectRefs.current[1] = el)}
                                                        >
                                                            <option value="0"></option>
                                                            {categories.map((category: AdvertisementCategoryInterface) => (
                                                                <option value={category.id} key={category.id}>
                                                                    {category.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <ArrowDropDown className='arrow-icon'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='space-between'
                                            >
                                                <Box sx={{mr: '2%'}} className='w-100'>
                                                    <div className="form-group">
                                                        <label htmlFor='priceFrom'>
                                                            Cena
                                                        </label>
                                                        <input
                                                            type='number'
                                                            name='priceFrom'
                                                            id='priceFrom'
                                                            className='rounded-borders form-control'
                                                            placeholder='Od'
                                                            value={priceFrom === 0 ? '' : priceFrom}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPriceFrom(parseInt(e.target.value))}
                                                        />
                                                    </div>
                                                </Box>
                                                <Box className='w-100'>
                                                    <div className="form-group">
                                                        <label htmlFor='priceTo'>
                                                            {/* tu label nechceme, vid navrh dizajnu, ale potom by html hundralo ze input nema label, tak aspon element tu je.... */}
                                                        </label>
                                                        <input
                                                            type='number'
                                                            name='priceTo'
                                                            id='priceTo'
                                                            className='rounded-borders form-control'
                                                            placeholder='Do'
                                                            value={priceTo === 0 ? '' : priceTo}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPriceTo(parseInt(e.target.value))}
                                                        />
                                                    </div>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <div className="form-group">
                                                <label htmlFor="sortBy">
                                                    Zoradiť
                                                </label>
                                                <div className='select-container'
                                                     ref={(el) => (selectContainers.current[2] = el)}
                                                     onClick={() => handleSelectContainerClick(2)}
                                                >
                                                    <select
                                                        name="sortBy"
                                                        id="sortBy"
                                                        className='form-control rounded-borders clickable'
                                                        value={sortBy ?? ''}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSortBy(e.target.value === '' ? null : e.target.value)}
                                                        ref={(el) => (selectRefs.current[2] = el)}

                                                    >
                                                        <option value=""></option>
                                                        {/* zakomentovane na ziadost mata: https://trello.com/c/EZJhnRWl/9-odstranit-filtre */}
                                                        {/*<option value="newest">*/}
                                                        {/*    Najnovšie (podľa dátumu dražby)*/}
                                                        {/*</option>*/}
                                                        {/*<option value="oldest">*/}
                                                        {/*    Najstaršie (podľa dátumu dražby)*/}
                                                        {/*</option>*/}
                                                        <option value="relevant">
                                                            Nadchádzajúce (podľa dátumu dražby)
                                                        </option>
                                                        {/* zakomentovane na ziadost mata: https://trello.com/c/EZJhnRWl/9-odstranit-filtre */}
                                                        {/*<option value="asc">*/}
                                                        {/*    Abecedne (a-z)*/}
                                                        {/*</option>*/}
                                                        {/*<option value="desc">*/}
                                                        {/*    Abecedne (z-a)*/}
                                                        {/*</option>*/}
                                                        <option value="low">
                                                            Podľa ceny (od najlacnejšieho)
                                                        </option>
                                                        <option value="high">
                                                            Podľa ceny (od najdrahšieho)
                                                        </option>
                                                    </select>
                                                    <ArrowDropDown className='arrow-icon'/>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <div className="w-100">
                                                <div className="text-center w-100">
                                                    <button type='button'
                                                            className='background-primary rounded-borders border-primary w-100'
                                                            onClick={handleSearch}
                                                    >
                                                        HĽADAJ
                                                        <SearchIcon/>
                                                    </button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {/* Zobrazime vsetky inzeraty - ktore vyhovuju filtru */}
                        <ShowAdvertisementPageList
                            advertisements={advertisements}
                            lastFetched={lastFetched}
                        />
                    </Grid>
                </Grid>
            </Container>
            {total !== advertisements.length && (
                <div className="blur-overlay"></div>
            )}
            <div className="blur-container">
                <div className="text-center">
                    {total === advertisements.length ? (
                        <Typography
                            variant='h6'
                            component='div'
                            style={{fontFamily: 'OpenSansBold'}}
                            className='text-primary'
                        >
                            Boli načítané {advertisements.length} z {total} inzerátov.
                        </Typography>
                    ) : (
                        <IconButton
                            aria-label="Načítať dalšie inzeráty"
                            onClick={() => {
                                setPage((prev) => prev + 1)
                            }}
                            style={{fontSize: '25px'}}
                        >
                            <KeyboardArrowDownIcon
                                className='clickable load-more-icon'
                            />
                        </IconButton>
                    )}
                </div>
            </div>
            <ToppedAdvertisements marginBottom='5%'/>
        </React.Fragment>
    )
}

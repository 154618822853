import * as React from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../../App"

export default function UserNotEnabledPage(): JSX.Element {
    //- context
    const authContext = React.useContext(AuthContext)
    //- init navigate
    const navigate = useNavigate()
    //- effect - ak je userLogged enabled tak tu nema co robit
    React.useEffect(() => {
        if (authContext.userLogged?.enabled) {
            navigate('/')
        }
    }, [])

    //- render
    return (
        <Container sx={{mt: '10%'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} display='flex' justifyContent='center' alignItems='center'>
                    <Typography variant='h5' component='div' className='text-primary' style={{fontFamily: 'OpenSansSemiBold'}}>
                        Váš účet bol vytvorený administrátorom a je potrebné ho aktivovať. Aktivovať účet môžete zmenením hesla.
                        Alebo prosím kontaktujte administrátora.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} display='flex' justifyContent='center' alignItems='center'>
                    <button onClick={() => navigate('/change-password')} className='background-primary rounded-borders border-primary' style={{fontFamily: 'OpenSansSemiBold', fontSize: '20px'}}>
                        Zmeniť si heslo
                    </button>
                </Grid>
            </Grid>
        </Container>
    )
}

import * as React from 'react'
import {Action, ClientContext} from 'react-fetching-library'
import {useNavigate, useParams} from 'react-router-dom'
import {PackageInterface} from '../../types/package/PackageInterface'
import {toast} from "react-toastify"
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import {Container, Typography} from '@mui/material'
import {AuthContext} from "../../App"
import Loading from "../../components/loader/Loading"

interface OrderPageFormProps {
    street: string | null,
    houseNumber: string | null,
    city: string | null,
    zipCode: string | null,
    country: string | null,
    confirm: boolean,
}

export default function OrderPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const ORDER_SUCCESSFULLY_CREATED: string = 'Objednávka bola úspešne vytvorená!'
    const ORDER_COULDNT_BE_CREATED: string = 'Objednávku sa nepodarilo vytvoriť!'
    //- context
    const authContext = React.useContext(AuthContext)
    const clientContext = React.useContext(ClientContext)
    //- state
    const [packageItem, setPackageItem] = React.useState<PackageInterface | null>(null)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    //- init params, navigate, get params from url
    const params = useParams()
    const navigate = useNavigate()
    const {packageId, advertisementId} = params

    //- actions
    const getPackageItemAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl(`/api/package/get/${packageId}`),
        headers: {
            Accept: 'application/json'
        },
    }

    const saveOrderAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/order/add`),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json'
        },
        body: {
            packageId: parseInt(packageId!),
            advertisementId: parseInt(advertisementId!),
        }
    }

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    //- effect for getPackageItem
    React.useEffect(() => {
        const getPackageItem = async (): Promise<void> => {
            const {payload, error, status} = await request(getPackageItemAction, clientContext)
            if (error && status === 500) {
                toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                setIsLoading(false)
                return
            }
            if (status === 200) {
                setPackageItem(payload)
            }
        }
        getPackageItem()
    }, [])


    React.useEffect(() => {
        const submitOrder = async (): Promise<void> => {
            const {payload, error, status} = await request(saveOrderAction, clientContext)
            if (error) {
                toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                setIsLoading(false)
                return
            }
            if (status === 401) {
                authContext.logout()
            }
            if (status === 403) {
                toast.error('Nemáte práva na vytvorenie objednávky!')
            }
            if (status === 200 || status === 201) {
                toast.success(ORDER_SUCCESSFULLY_CREATED)
                if (payload.data.isRedirectToPaymentGate) {
                    navigate('/createPayment/' + payload.data.order.payment.uniqueId)
                } else {
                    navigate(`/order-created/${payload.data.order.id}/${advertisementId}`)
                }
            } else {
                toast.error(ORDER_COULDNT_BE_CREATED)
            }
            setIsLoading(false)
        }
        submitOrder()
    }, [])

    //- loading
    if (isLoading) {
        return (
            <Loading/>
        )
    }

    return (
        <Container sx={{mt: '3%', mb: '2%'}}>
            <Typography variant='h4' component='div' className='text-primary' style={{fontFamily: 'OpenSansSemiBold'}}>
                Objednávka balíka {packageItem?.name}
            </Typography>
        </Container>
    )
}

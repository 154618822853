import * as React from 'react'
import { Action, ClientContext } from "react-fetching-library"
import { toast } from "react-toastify"
import { Link, useNavigate } from 'react-router-dom'
import { UserAddOrEditComponentProps, UserAddOrEditErrorProps, UserAddOrEditProps } from "./types/UserAddOrEditProps"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import { Card, CardContent, Grid, Tooltip, Typography } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { generateValidPassword } from "../../utils/password/passwordUtil"
import Loading from "../loader/Loading"
import AuthContextType from "../../types/AuthContext/AuthContextType"
import { AuthContext } from "../../App"


export default function UserRegisterOrAdd(props: UserAddOrEditComponentProps): JSX.Element {
    const EMAIL_NOT_VALID_MESSAGE: string = 'Hodnota nie je platná emailová adresa'
    const PASSWORD_NOT_VALID_MESSAGE: string = 'Hodnota nie je platné heslo. Heslo musí obsahovať aspoň 10 znakov, malé a veľké písmená, číslo a špeciálny znak'
    const PASWWORDS_DO_NOT_MATCH_MESSAGE: string = 'Heslá sa nezhodujú'
    const VERIFY_YOU_ARE_NOT_A_ROBOT: string = 'Overte prosím, že nie ste robot'
    const clientContext = React.useContext(ClientContext)
    const authContext: AuthContextType = React.useContext(AuthContext)
    const [formData, setFormData] = React.useState<UserAddOrEditProps>({
        title: null,
        firstName: null,
        lastName: null,
        email: null,
        passwordPlain: null,
        passwordPlainRepeat: null,
        telephoneNumber: null,
        addressString: null,
        urlAddress: null,
        isFO: true,
        ico: null,
        dic: null,
        icDph: null,
        nameOfCompany: null,
        address: {
            street: null,
            houseNumber: null,
            city: null,
            zipCode: null,
            country: null,
        },
        sendEmail: false,
    })
    const [isEmailValid, setIsEmailValid] = React.useState<boolean>(true)
    const [isPasswordValid, setIsPasswordValid] = React.useState<boolean>(false)
    const [isPasswordMatched, setIsPasswordMatched] = React.useState<boolean>(false)
    const [isAddressAsString] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [passwordVisible, setPasswordVisible] = React.useState(false)
    const [didAcceptProcessingOfPersonalData, setDidAcceptProcessingOfPersonalData] = React.useState<boolean>(false)
    // errors
    const [errors, setErrors] = React.useState<UserAddOrEditErrorProps>({
        titleErrors: [],
        firstNameErrors: [],
        lastNameErrors: [],
        emailErrors: [],
        passwordPlainErrors: [],
        passwordPlainRepeatErrors: [],
        telephoneNumberErrors: [],
        icoErrors: [],
        nameOfCompanyErrors: [],
        dicErrors: [],
        icDphErrors: [],
        addressErrors: {
            streetErrors: [],
            houseNumberErrors: [],
            cityErrors: [],
            zipCodeErrors: [],
            countryErrors: [],
        },
        addressErrorsString: [],
        urlAddressErrors: [],
    })
    const {
        titleErrors,
        firstNameErrors,
        lastNameErrors,
        emailErrors,
        passwordPlainErrors,
        passwordPlainRepeatErrors,
        telephoneNumberErrors,
        addressErrors,
        addressErrorsString,
        icoErrors,
        nameOfCompanyErrors,
        dicErrors,
        icDphErrors
    } = errors

    // destructure from formData
    const {
        title,
        firstName,
        lastName,
        email,
        isFO,
        ico,
        dic,
        icDph,
        nameOfCompany,
        passwordPlain,
        passwordPlainRepeat,
        telephoneNumber,
        addressString,
        address,
        sendEmail,
    } = formData

    // init navigate
    const navigate = useNavigate()
    const {executeRecaptcha} = useGoogleReCaptcha()

    //- actions
    const verifyRecaptchaAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/recaptcha/verify'),
        headers: {
            Accept: 'application/json'
        },
    }

    const validateEmail = (email: string | null): void => {
        emailErrors.splice(0, emailErrors.length)
        const emailValidationRegex: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        if (email === null) {
            const index: number = emailErrors.indexOf(EMAIL_NOT_VALID_MESSAGE)
            if (index === -1) {
                emailErrors.push(EMAIL_NOT_VALID_MESSAGE)
            }
        } else {
            const isEmailValid: boolean = emailValidationRegex.test(email)
            if (!isEmailValid) {
                const index: number = emailErrors.indexOf(EMAIL_NOT_VALID_MESSAGE)
                if (index === -1) {
                    emailErrors.push(EMAIL_NOT_VALID_MESSAGE)
                }
            } else {
                const index: number = emailErrors.indexOf(EMAIL_NOT_VALID_MESSAGE)
                if (index > -1) {
                    emailErrors.splice(index, 1)
                }
            }
        }
        // update state
        setErrors({
            ...errors,
            emailErrors: emailErrors
        })
        setIsEmailValid(emailErrors.length === 0)
    }

    // validate password regex
    const validatePasswordRegex = (password: string | null): void => {
        const passwordValidationRegex: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$_+-/<>%^&*-]).{10,}$/i
        if (password === null) {
            const index: number = passwordPlainErrors.indexOf(PASSWORD_NOT_VALID_MESSAGE)
            if (index === -1) {
                passwordPlainErrors.push(PASSWORD_NOT_VALID_MESSAGE)
            }
        } else {
            const isPasswordValid: boolean = passwordValidationRegex.test(password)
            if (!isPasswordValid) {
                const index: number = passwordPlainErrors.indexOf(PASSWORD_NOT_VALID_MESSAGE)
                if (index === -1) {
                    passwordPlainErrors.push(PASSWORD_NOT_VALID_MESSAGE)
                }
            } else {
                const index: number = passwordPlainErrors.indexOf(PASSWORD_NOT_VALID_MESSAGE)
                if (index > -1) {
                    passwordPlainErrors.splice(index, 1)
                }
            }
        }
        // update state
        setErrors({
            ...errors,
            passwordPlainErrors: passwordPlainErrors
        })
        setIsPasswordValid(passwordPlainErrors.length === 0)
    }

    const validatePasswordMatch = (password: string | null, passwordRepeat: string | null): void => {
        const isPasswordMatched = password === passwordRepeat
        if (!isPasswordMatched) {
            const index: number = passwordPlainRepeatErrors.indexOf(PASWWORDS_DO_NOT_MATCH_MESSAGE)
            if (index === -1) {
                passwordPlainRepeatErrors.push(PASWWORDS_DO_NOT_MATCH_MESSAGE)
            }
        } else {
            const index: number = passwordPlainRepeatErrors.indexOf(PASWWORDS_DO_NOT_MATCH_MESSAGE)
            if (index > -1) {
                passwordPlainRepeatErrors.splice(index, 1)
            }
        }
        // update state
        setErrors({
            ...errors,
            passwordPlainRepeatErrors: passwordPlainRepeatErrors
        })
        setIsPasswordMatched(passwordPlainRepeatErrors.length === 0)
        validatePasswordRegex(password)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'email') {
            validateEmail(event.target.value)
        }
        if (event.target.name === 'passwordPlain') {
            validatePasswordRegex(event.target.value)
        }
        if (event.target.name === 'passwordPlainRepeat') {
            validatePasswordMatch(passwordPlain, event.target.value)
        }
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFormData({
            ...formData,
            address: {
                ...address,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleGeneratePassword = (): void => {
        const generatedPassword: string = generateValidPassword()
        // setneme do state
        setFormData({
            ...formData,
            passwordPlain: generatedPassword,
            passwordPlainRepeat: generatedPassword
        })
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        // pre istotu validacia znovu
        validateEmail(email)
        if (!isEmailValid) {
            toast.error(props.messageOnError)
            return
        }
        setIsLoading(true)
        const recaptchaToken = await executeRecaptcha?.('userRegisterOrAdd')
        if (recaptchaToken || !props.isRecaptchaEnabled) {
            //- verify recaptcha
            const verifyRecaptchaAction2: Action = {
                ...verifyRecaptchaAction,
                body: {
                    recaptchaToken: recaptchaToken,
                }
            }
            let canSubmitForm: boolean = false
            if (props.isRecaptchaEnabled) {
                const { error, status } = await request(verifyRecaptchaAction2, clientContext)
                if (error) {
                    toast.error(props.messageOnError)
                    setIsLoading(false)
                    return
                }
                canSubmitForm = status === 200 || status === 201
            } else {
                canSubmitForm = true
            }
            if (canSubmitForm) {
                const action2 = {
                    ...props.action,
                    body: {
                        title: isFO ? title : null,
                        firstName: isFO ? firstName : null,
                        lastName: isFO ? lastName : null,
                        email: email,
                        passwordPlain: passwordPlain,
                        telephoneNumber: telephoneNumber,
                        addressString: isAddressAsString ? addressString : null,
                        ico: !isFO ? ico : null,
                        dic: !isFO ? dic : null,
                        icDph: !isFO ? icDph : null,
                        address: isAddressAsString ? null : address,
                        nameOfCompany: !isFO ? nameOfCompany : null,
                        isFO: isFO,
                        sendEmailIfCreated: sendEmail,
                        didAcceptProcessingOfPersonalData: !props.isRegistration ? true : didAcceptProcessingOfPersonalData,
                        url: null,
                    }
                }
                const {payload, error, status} = await request(action2, clientContext)
                setIsLoading(false)
                if (error && status === 500) {
                    toast.error(props.messageOnError)
                    return
                }
                if (!payload.success) {
                    toast.error(props.messageOnError)
                    for (let key in payload.errors) {
                        switch (key) { // takyto krepy switch zatial, mozno v buducnosti prepisat na nieco rozumnejsie
                            case 'title':
                                titleErrors.push(payload.errors[key])
                                break
                            case 'firstName':
                                firstNameErrors.push(payload.errors[key])
                                break
                            case 'ico':
                                icoErrors.push(payload.errors[key])
                                break
                            case 'nameOfCompany':
                                nameOfCompanyErrors.push(payload.errors[key])
                                break
                            case 'lastName':
                                lastNameErrors.push(payload.errors[key])
                                break
                            case 'didAcceptProcessingOfPersonalData':
                                toast.error(payload.errors[key])
                                break
                            case 'email':
                                if (emailErrors.indexOf(payload.errors[key]) === -1) {
                                    emailErrors.push(payload.errors[key])
                                    setIsEmailValid(false)
                                }
                                break
                            case 'passwordPlain':
                                if (passwordPlainErrors.indexOf(payload.errors[key]) === -1) {
                                    passwordPlainErrors.push(payload.errors[key])
                                    setIsPasswordValid(false)
                                }
                                break
                            case 'telephoneNumber':
                                telephoneNumberErrors.push(payload.errors[key])
                                break
                            case 'address':
                                addressErrorsString.push(payload.errors[key])
                                break
                            case 'street':
                                addressErrors.streetErrors.push(payload.errors[key])
                                break
                            case 'houseNumber':
                                addressErrors.houseNumberErrors.push(payload.errors[key])
                                break
                            case 'city':
                                addressErrors.cityErrors.push(payload.errors[key])
                                break
                            case 'zipCode':
                                addressErrors.zipCodeErrors.push(payload.errors[key])
                                break
                            case 'country':
                                addressErrors.countryErrors.push(payload.errors[key])
                                break
                            default:
                                break
                        }
                        setErrors({
                            ...errors,
                            titleErrors: titleErrors,
                            firstNameErrors: firstNameErrors,
                            lastNameErrors: lastNameErrors,
                            emailErrors: emailErrors,
                            passwordPlainErrors: passwordPlainErrors,
                            telephoneNumberErrors: telephoneNumberErrors,
                            addressErrorsString: addressErrorsString
                        })
                    }
                } else if (payload.success) {
                    toast.success(props.messageOnSuccess)
                    navigate(props.urlToRedirectToOnSuccess)
                }
                if (status === 401) {
                    if (!props.isRegistration) {
                        authContext.logout()
                    }
                }
            } else {
                toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
            }
        } else {
            toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
        }
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <Typography variant='h4' style={{fontFamily: 'OpenSansSemiBold'}} className='text-primary'>
                {isFO ? <span>{title} {firstName} {lastName}</span> : <span>{nameOfCompany}</span>}
            </Typography>
            <form onSubmit={handleSubmit} style={{marginTop: '5%', marginBottom: '2%'}}>
                <div className="form-group">
                    <label htmlFor="isFO" className='text-primary'  style={{fontFamily: 'OpenSansBold'}}>
                        <input type="checkbox" name="isFO" id="isFO" checked={!isFO}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                   ...formData,
                                   ['isFO']: !e.target.checked
                               })}/>
                        &nbsp;Používateľské konto je pravnická osoba (firma)
                    </label>
                </div>
                <Typography variant='h6' style={{fontFamily: 'OpenSansBold', marginBottom: '2%'}}
                            className='text-primary'>
                    Osobné údaje
                </Typography>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px'}}>
                        <Grid container spacing={2}>
                            {isFO ? (
                                <React.Fragment>
                                    <Grid item xs={12} sm={3}>
                                        <div className='form-group'>
                                            <label htmlFor='title' className='text-primary'
                                                   style={{fontFamily: 'OpenSansBold'}}>
                                                Titul
                                            </label>
                                            <input type='text' name='title' id='title'
                                                   className={(titleErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                   value={title ?? ''}
                                                   onChange={handleChange}/>
                                            <div>
                                                <div>
                                                    {titleErrors.map((error: string, index: number) => (
                                                        <p key={index} className='error'>
                                                            {error}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <div className='form-group'>
                                            <label htmlFor='firstName' className='required text-primary'
                                                   style={{fontFamily: 'OpenSansBold'}}>
                                                Meno
                                            </label>
                                            <input type='text' name='firstName' id='firstName'
                                                   className={(firstNameErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                   value={firstName ?? ''}
                                                   onChange={handleChange}/>
                                            <div>
                                                {firstNameErrors.map((error: string, index: number) => (
                                                    <p key={index} className='error'>
                                                        {error}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <div className='form-group'>
                                            <label htmlFor='lastName' className='required text-primary'
                                                   style={{fontFamily: 'OpenSansBold'}}>
                                                Priezvisko
                                            </label>
                                            <input type='text' name='lastName' id='lastName'
                                                   className={(lastNameErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                   value={lastName ?? ''}
                                                   onChange={handleChange}/>
                                            <div>
                                                {lastNameErrors.map((error: string, index: number) => (
                                                    <p key={index} className='error'>
                                                        {error}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid item xs={12} sm={3}>
                                        <div className='form-group'>
                                            <label htmlFor='nameOfCompany' className='text-primary required'
                                                   style={{fontFamily: 'OpenSansBold'}}>
                                                Obchodné meno
                                            </label>
                                            <input type='text' name='nameOfCompany' id='nameOfCompany'
                                                   className={(nameOfCompanyErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                   value={nameOfCompany ?? ''}
                                                   onChange={handleChange}/>
                                            <div>
                                                <div>
                                                    {nameOfCompanyErrors.map((error: string, index: number) => (
                                                        <p key={index} className='error'>
                                                            {error}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <div className='form-group'>
                                            <label htmlFor='ico' className='required text-primary'
                                                   style={{fontFamily: 'OpenSansBold'}}>
                                                IČO
                                            </label>
                                            <input type='text' name='ico' id='ico'
                                                   className={(icoErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                   value={ico ?? ''}
                                                   onChange={handleChange}/>
                                            <div>
                                                {icoErrors.map((error: string, index: number) => (
                                                    <p key={index} className='error'>
                                                        {error}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <div className='form-group'>
                                            <label htmlFor='dic' className='text-primary'
                                                   style={{fontFamily: 'OpenSansBold'}}>
                                                DIČ
                                            </label>
                                            <input type='text' name='dic' id='dic'
                                                   className={(dicErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                   value={dic ?? ''}
                                                   onChange={handleChange}/>
                                            <div>
                                                {dicErrors.map((error: string, index: number) => (
                                                    <p key={index} className='error'>
                                                        {error}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <div className='form-group'>
                                            <label htmlFor='lastName' className='text-primary'
                                                   style={{fontFamily: 'OpenSansBold'}}>
                                                IČ DPH
                                            </label>
                                            <input type='text' name='icDph' id='icDph'
                                                   className={(icDphErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                   value={icDph ?? ''}
                                                   onChange={handleChange}/>
                                            <div>
                                                {icDphErrors.map((error: string, index: number) => (
                                                    <p key={index} className='error'>
                                                        {error}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    </Grid>
                                </React.Fragment>
                            )}
                            <Grid item xs={12} sm={3}>
                                <div className='form-group'>
                                    <label htmlFor='email' className='required text-primary'
                                           style={{fontFamily: 'OpenSansBold'}}>
                                        Email
                                    </label>
                                    <input type='email' name='email' id='email'
                                           className={(!isEmailValid && emailErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                           value={email ?? ''}
                                           onChange={handleChange}/>
                                    <div>
                                        {!isEmailValid && emailErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className='form-group'>
                                    <label htmlFor='telephoneNumber' className='text-primary required'
                                           style={{fontFamily: 'OpenSansBold'}}>
                                        Telefónne číslo
                                    </label>
                                    <input type='text' name='telephoneNumber' id='telephoneNumber'
                                           className={(telephoneNumberErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                           value={telephoneNumber ?? ''} onChange={handleChange}/>
                                    <div>
                                        {telephoneNumberErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Typography variant='h6' style={{fontFamily: 'OpenSansBold', marginTop: '5%', marginBottom: '2%'}}
                            className='text-primary'>
                    {isFO ? 'Adresa trvalého pobytu' : 'Sídlo'}
                </Typography>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <div className='form-group'>
                                    <label htmlFor='street' className='text-primary required'
                                           style={{fontFamily: 'OpenSansBold'}}>
                                        Ulica
                                    </label>
                                    <input type='text' name='street' id='street' value={address.street ?? ''}
                                           onChange={handleChangeAddress}
                                           className={(addressErrors.streetErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}/>
                                    <div>
                                        {addressErrors.streetErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className='form-group'>
                                    <label htmlFor='houseNumber' className='text-primary required'
                                           style={{fontFamily: 'OpenSansBold'}}>
                                        Súpisné číslo
                                    </label>
                                    <input type='number' name='houseNumber' id='houseNumber'
                                           value={address.houseNumber ?? ''}
                                           onChange={handleChangeAddress}
                                           className={(addressErrors.houseNumberErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}/>
                                    <div>
                                        {addressErrors.houseNumberErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className='form-group'>
                                    <label htmlFor='city' className='text-primary required'
                                           style={{fontFamily: 'OpenSansBold'}}>
                                        Mesto
                                    </label>
                                    <input type='text' name='city' id='city' value={address.city ?? ''}
                                           onChange={handleChangeAddress}
                                           className={(addressErrors.cityErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}/>
                                    <div>
                                        {addressErrors.cityErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className='form-group'>
                                    <label htmlFor='zipCode' className='text-primary required'
                                           style={{fontFamily: 'OpenSansBold'}}>
                                        PSČ
                                    </label>
                                    <input type='text' name='zipCode' id='zipCode' value={address.zipCode ?? ''}
                                           onChange={handleChangeAddress}
                                           className={(addressErrors.zipCodeErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}/>
                                    <div>
                                        {addressErrors.zipCodeErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className='form-group'>
                                    <label htmlFor='country' className='text-primary'
                                           style={{fontFamily: 'OpenSansBold'}}>
                                        Krajina
                                    </label>
                                    <input type='text' name='country' id='country' value={address.country ?? ''}
                                           onChange={handleChangeAddress}
                                           className={(addressErrors.countryErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}/>
                                    <div>
                                        {addressErrors.countryErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Typography variant='h6' style={{fontFamily: 'OpenSansBold', marginTop: '5%', marginBottom: '2%'}}
                            className='text-primary'>
                    Heslo
                </Typography>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px'}}>
                        <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                                <div className='form-group'>
                                    <label htmlFor='passwordPlain' className='required text-primary'
                                           style={{fontFamily: 'OpenSansBold'}}>
                                        Heslo
                                    </label>
                                    <div className='password-input-wrapper'>
                                        <div className='input-container'>
                                            <input
                                                type={passwordVisible ? 'text' : 'password'}
                                                name='passwordPlain'
                                                id='passwordPlain'
                                                className={((!isPasswordValid || !isPasswordMatched) && passwordPlainErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                value={passwordPlain ?? ''}
                                                onChange={handleChange}
                                            />
                                            <div className='password-toggle-icon' onClick={togglePasswordVisibility}>
                                                {passwordVisible ? (
                                                    <Tooltip title='Skryť heslá'>
                                                        <VisibilityOff className='icon'/>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title='Zobraziť heslá'>
                                                        <Visibility className='icon'/>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {(!isPasswordValid || !isPasswordMatched) && passwordPlainErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <div className='form-group'>
                                    <label htmlFor='passwordPlain' className='required text-primary'
                                           style={{fontFamily: 'OpenSansBold'}}>
                                        Heslo znova
                                    </label>
                                    <div className='password-input-wrapper'>
                                        <div className='input-container'>
                                            <input
                                                type={passwordVisible ? 'text' : 'password'}
                                                name='passwordPlainRepeat'
                                                id='passwordPlainRepeat'
                                                className={(!isPasswordMatched && passwordPlainRepeatErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                value={passwordPlainRepeat ?? ''} onChange={handleChange}
                                            />
                                            <div className='password-toggle-icon' onClick={togglePasswordVisibility}>
                                                {passwordVisible ? (
                                                    <Tooltip title='Skryť heslá'>
                                                        <VisibilityOff className='icon'/>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title='Zobraziť heslá'>
                                                        <Visibility className='icon'/>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {!isPasswordMatched && passwordPlainRepeatErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <div className="form-group w-100">
                                    <div className="text-center w-100" style={{marginTop: '5%'}}>
                                        <button
                                            type='button'
                                            className='background-primary rounded-borders border-primary'
                                            style={{fontFamily: 'OpenSansBold', fontSize: '16px', padding: '10px'}}
                                            onClick={handleGeneratePassword}
                                        >
                                            Vygenerovať heslo
                                        </button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                {!props.isRegistration && props.userLogged?.canCreateUsers && (
                    <div className="form-group mt-4">
                        <label htmlFor="sendEmail" className='text-primary' style={{fontFamily: 'OpenSansBold'}}>
                            <input type="checkbox" name="sendEmail" id="sendEmail" checked={sendEmail}
                                   onChange={
                                       (e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                           ...formData,
                                           ['sendEmail']: e.target.checked
                                       })
                                   }
                            />
                            &nbsp;Odoslať použivateľovi email o vytvorení účtu
                        </label>
                    </div>
                )}
                {props.isRegistration && (
                    <React.Fragment>
                        <div className="form-group mt-4">
                            <label htmlFor="didAcceptProcessingOfPersonalData" className='text-primary' style={{fontFamily: 'OpenSansBold'}}>
                                <input type="checkbox" name="didAcceptProcessingOfPersonalData" id="didAcceptProcessingOfPersonalData" checked={didAcceptProcessingOfPersonalData}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDidAcceptProcessingOfPersonalData(e.target.checked)}
                                />
                                &nbsp;Súhlasím so spracovaním osobných údajov
                            </label>
                        </div>
                        <p style={{fontFamily: 'OpenSansBold'}} className='text-primary mt-3'>
                            Ak už máte účet, môžte sa prihlasiť kliknutím na tento <Link to='/login'>link</Link>
                        </p>
                    </React.Fragment>
                )}
                <div className="w-100 text-center mt-4">
                    <button
                        type="submit"
                        className='background-primary border-primary rounded-borders'
                        style={{fontFamily: 'OpenSansBold', fontSize: '22px', padding: '10px'}}
                    >
                        {props.isRegistration ? 'Registrovať sa' : 'Pridať použivateľa'}
                    </button>
                </div>
            </form>
        </React.Fragment>
    )
}

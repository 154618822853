import * as React from 'react'
import { AuthContext } from '../../App'
import { Action } from "react-fetching-library"
import { getApiUrlFromRelativeUrl } from '../../utils/api/ApiUtil'
import MyAdvertisementsCardList from "../../components/advertisement/MyAdvertisementsCardList"
import { useNavigate } from "react-router-dom"
import { Typography, Container } from '@mui/material'

export default function MyAdvertisementsPage(): JSX.Element {
    // context
    const authContext = React.useContext(AuthContext)
    const navigate = useNavigate()
    // actions
    const getAdvertisementsAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/advertisements/all/byUser/paginated/${authContext.userLogged?.id}`),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json'
        }
    }

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    return (
        <React.Fragment>
            <Container sx={{mt: '5%'}}>
                <Typography variant="h4" component="div" className='text-primary' style={{fontFamily: 'OpenSansBold', marginBottom: '2%', marginLeft: '3%'}}>
                    Moje inzeráty
                </Typography>
                {/* Zobrazime inzeraty */}
                <MyAdvertisementsCardList
                    action={getAdvertisementsAction}
                    canEditAdvertisements={true}
                    canDeleteAdvertisements={true}
                />
            </Container>
        </React.Fragment>
    )
}
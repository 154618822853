import * as React from 'react'
import { MenuContext } from '../../App'
import UserLoggedInterface from "../../types/User/UserLoggedInterface"
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { FaTimesCircle } from "react-icons/fa"
import MenuItem from '@mui/material/MenuItem'
import MenuItemInterface from '../../types/Menu/MenuItemInterface'
import MenuContextType from "../../types/MenuContext/MenuContextType"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Drawer, ListItemText } from '@mui/material'
import Loading from "../loader/Loading"
import { useLocation } from 'react-router-dom'
import logo_biele from '../../assets/images/logo_biele.png'
import logo_modre from '../../assets/images/logo_modre.png'
import my_profile_photo from '../../assets/icons/my_profile_photo.svg'
import {getApiUrlFromRelativeUrl} from "../../utils/api/ApiUtil";

interface NavbarComponentProps {
    isUserLoggedIn: boolean
    userLogged: UserLoggedInterface | null,
    handleLogout: () => void,
    isLoading: boolean,
}

export default function Navbar(props: NavbarComponentProps): JSX.Element {
    // context
    const menuContext: MenuContextType = React.useContext(MenuContext)
    // pages + state
    const pages = menuContext.getMenuItemsBasic()
    const pagesAll = menuContext.getMenuItemsByIsUserLogged(props.isUserLoggedIn)
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
    const [drawerOpen, setDrawerOpen] = React.useState<boolean | undefined>(false)
    const location = useLocation()
    const [key, setKey] = React.useState(0)

    //- effect - toto tu je preto, ze ak cez navigate sa zmeni pathname, tak sa nereloadne tento component a svieti ze je aktivne nieco, co uz nie je pravda... takto povieme, reactu aby ho unmountol a znova mountol(nacital)
    React.useEffect(() => {
        setKey((prevKey) => prevKey + 1)
    }, [location.pathname])
    // handlers
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen)
    }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    // loading
    if (props.isLoading) {
        return (
            <Loading/>
        )
    }

    // render
    return (
        <React.Fragment key={key}>
            <AppBar position="static" sx={{backgroundColor: '#1851AB'}}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src={logo_biele} alt="logo" className='logo' width={130} height={'auto'}/>
                        </Typography>

                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleDrawerToggle}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Drawer
                                variant="temporary"
                                anchor='left'
                                open={drawerOpen}
                                onClose={handleDrawerToggle}
                                PaperProps={{
                                    sx: {
                                        width: '100vw'
                                    }
                                }}
                                ModalProps={{
                                    keepMounted: true, // zevraj lepsi perfomance na telefone
                                }}
                            >
                                <Box
                                    position="relative" // Add position relative to the container
                                    display="flex"
                                    justifyContent="flex-end" // Push the icon to the right
                                    alignItems="center" // Center the logo vertically
                                    sx={{m: 2}}
                                >
                                    <FaTimesCircle
                                        onClick={handleDrawerToggle}
                                        style={{fontSize: '18px'}}
                                        className='drawer-toggler drawer-close'
                                    />
                                    <Typography
                                        variant="h5"
                                        noWrap
                                        component="a"
                                        href="/"
                                        sx={{
                                            position: 'absolute', // Position the logo absolutely
                                            left: '50%', // Move it 50% from the left of the container
                                            transform: 'translateX(-50%)', // Center it horizontally
                                            fontFamily: 'monospace',
                                            fontWeight: 700,
                                            letterSpacing: '.3rem',
                                            color: 'inherit',
                                            textDecoration: 'none',
                                            display: 'flex',
                                            alignItems: 'center', // Center the content vertically
                                        }}
                                    >
                                        <img src={logo_modre} alt="logo" className='logo' width={130} height={'auto'}/>
                                    </Typography>
                                </Box>
                                {pagesAll.map((page: MenuItemInterface, index: number) => (
                                    <React.Fragment key={index}>
                                        {menuContext.checkAccessToMenuItem(page) && (
                                            <MenuItem key={page.id} onClick={handleDrawerToggle}>
                                                <ListItemText style={{textAlign: 'center', width: '100%'}}>
                                                    {menuContext.isMenuItemLogout(page) ? (
                                                        <Button
                                                            className='text-primary'
                                                            onClick={props.handleLogout}
                                                            style={{
                                                                fontFamily: 'OpenSansBold',
                                                                fontSize: '12px'
                                                            }}
                                                        >
                                                            <span
                                                                className={`menu-item-user-menu ${drawerOpen && menuContext.isItemActive(page) ? 'active' : ''}`}
                                                                style={{paddingBottom: '5px'}}
                                                            >
                                                                {page.name}
                                                            </span>
                                                        </Button>
                                                    ) : (
                                                        <Button href={page.url}
                                                                className='text-primary'
                                                                style={{
                                                                    fontFamily: 'OpenSansBold',
                                                                    fontSize: '12px'
                                                                }}
                                                        >
                                                            <span
                                                                className={`menu-item-user-menu ${drawerOpen && menuContext.isItemActive(page) ? 'active' : ''}`}
                                                                style={{paddingBottom: '5px'}}
                                                            >
                                                                {page.name}
                                                            </span>
                                                        </Button>
                                                    )}
                                                </ListItemText>
                                            </MenuItem>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Drawer>
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: {xs: 'flex', md: 'none'},
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src={logo_biele} alt="logo" className='logo' width={130} height={'auto'}/>
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            {pages.map((page: MenuItemInterface, index: number) => (
                                <React.Fragment key={index}>
                                    {menuContext.checkAccessToMenuItem(page) ? (
                                        <Button
                                            key={page.id}
                                            href={page.url}
                                            sx={{my: 2, color: 'lightGrey', display: 'block'}}
                                            style={page.name === 'FAQ' ? {fontFamily: 'OpenSansBold', fontSize: '12px', marginRight: '-25px'}
                                                : {fontFamily: 'OpenSansBold', fontSize: '12px'}
                                        }
                                            className={`menu-item-navbar ${menuContext.isItemActive(page) ? 'active' : ''}`}
                                        >
                                            {page.name}
                                        </Button>
                                    ) : null}
                                </React.Fragment>
                            ))}
                        </Box>
                        {props.isUserLoggedIn ? (
                            <Box sx={{flexGrow: 0}}>
                                <Button
                                    sx={{color: 'lightGrey', display: {xs: 'none', md: 'flex'}}}
                                    style={{fontFamily: 'OpenSansBold', fontSize: '12px'}}
                                    className={`clickable menu-item-navbar ${(Boolean(anchorElUser) || menuContext.isAnyItemActive(menuContext.getUserMenuItems())) ? 'active' : ''}`}
                                    onClick={handleOpenUserMenu}
                                >
                                    <img src={(props.userLogged !== null && props.userLogged.avatar !== null && props.userLogged.avatar !== undefined) ?  props.userLogged.avatar.url !== null && props.userLogged.avatar.url !== undefined ? getApiUrlFromRelativeUrl(props.userLogged.avatar.url) : my_profile_photo : my_profile_photo} alt="logo" width={32} height={32}
                                         style={{marginRight: '5px', border: '1px solid #fff', borderRadius: '50%', objectFit: 'contain'}}/>
                                    {props.userLogged?.isFO ? (props.userLogged?.firstName + ' ' + props.userLogged?.lastName) : props.userLogged?.nameOfCompany}
                                    {Boolean(anchorElUser) ? (
                                        <KeyboardArrowUpIcon sx={{cursor: 'pointer'}}/>
                                    ) : (
                                        <KeyboardArrowDownIcon sx={{cursor: 'pointer'}}/>
                                    )}
                                </Button>
                                <Menu
                                    sx={{mt: '45px'}}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {menuContext.getUserMenuItems().map((page: MenuItemInterface) => (
                                        <MenuItem key={page.id} onClick={handleCloseUserMenu}>
                                            {menuContext.isMenuItemLogout(page) ? (
                                                <Button
                                                    onClick={props.handleLogout}
                                                    style={{fontFamily: 'OpenSansBold', fontSize: '12px'}}

                                                >
                                                    <span
                                                        className={`menu-item-user-menu ${Boolean(anchorElUser) && menuContext.isItemActive(page) ? 'active' : ''}`}
                                                        style={{paddingBottom: '5px'}}
                                                    >
                                                        {page.name}
                                                    </span>
                                                </Button>
                                            ) : (
                                                <Button
                                                    href={page.url}
                                                    style={{fontFamily: 'OpenSansBold', fontSize: '12px'}}
                                                >
                                                    <span
                                                        className={`menu-item-user-menu ${Boolean(anchorElUser) && menuContext.isItemActive(page) ? 'active' : ''}`}
                                                        style={{paddingBottom: '5px'}}
                                                    >
                                                        {page.name}
                                                    </span>
                                                </Button>
                                            )}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        ) : (
                            <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
                                {menuContext.getLoginAndRegistrationMenuItems().map((page: MenuItemInterface) => (
                                    <Button
                                        key={page.id}
                                        href={page.url}
                                        style={{fontFamily: 'OpenSansBold', fontSize: '12px'}}
                                        sx={{my: 2, color: 'lightGrey', display: 'block'}}
                                        className={`menu-item-navbar ${menuContext.isItemActive(page) ? 'active' : ''}`}
                                    >
                                        {page.name}
                                    </Button>
                                ))}
                            </Box>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
        </React.Fragment>
    )
}

import * as React from 'react'
import { Action, ClientContext } from "react-fetching-library"
import { toast } from "react-toastify"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Box, Card, CardContent, Container, Grid, Typography } from "@mui/material"
import Loading from "../../components/loader/Loading"

export default function ResetPasswordRequest(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const EMAIL_NOT_VALID_MESSAGE: string = 'Hodnota nie je platná emailová adresa'
    const EMAIL_SENT_MESSAGE: string = 'Email bol odoslaný, skontrolujte si email prosím'
    const VERIFY_YOU_ARE_NOT_A_ROBOT: string = 'Overte prosím, že nie ste robot'
    // context
    const clientContext = React.useContext(ClientContext)
    // state
    const [email, setEmail] = React.useState<string | null>(null)
    const [isEmailValid, setIsEmailValid] = React.useState<boolean>(true) // defaultne je true, lebo null je validny
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null) // defaultne je null, lebo nemame ziaden error
    const [isEmailSent, setIsEmailSent] = React.useState<boolean>(false) // defaultne je false, lebo este neodoslal email
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    //- recaptcha
    const IS_RECAPTCHA_ENABLED: boolean = process.env.REACT_APP_IS_RECAPTCHA_ENABLED === 'true'
    const {executeRecaptcha} = useGoogleReCaptcha()


    // actions
    const resetPasswordRequestAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/resetPassword/request'),
        headers: {
            Accept: 'application/json'
        },
        body: {
            email: email
        },
    }

    const verifyRecaptchaAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/recaptcha/verify'),
        headers: {
            Accept: 'application/json'
        },
    }

    // onChange
    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.target.value)
        validateEmail(event.target.value)
    }

    const validateEmail = (email: string | null): void => {
        const emailValidationRegex: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        if (email === null) {
            setIsEmailValid(true) // ak je null, tak sa nevaliduje
            setErrorMessage(null)
        } else {
            if (emailValidationRegex.test(email)) {
                setIsEmailValid(true)
                setErrorMessage(null)
            } else {
                setIsEmailValid(false)
                setErrorMessage(EMAIL_NOT_VALID_MESSAGE)
            }
        }
    }

    // submit
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        // nemozme pustit usera dalej ak je email nevalidny || ak je null
        if (!isEmailValid || email === null) {
            setIsEmailValid(false)
            setErrorMessage(EMAIL_NOT_VALID_MESSAGE)
            return
        }
        setIsLoading(true)
        const recaptchaToken = await executeRecaptcha?.('reset_password_request')
        if (recaptchaToken || !IS_RECAPTCHA_ENABLED) {
            //- verify recaptcha
            const verifyRecaptchaAction2: Action = {
                ...verifyRecaptchaAction,
                body: {
                    recaptchaToken: recaptchaToken,
                }
            }
            let canSubmitForm: boolean = false
            if (IS_RECAPTCHA_ENABLED) {
                const {payload, error, status} = await request(verifyRecaptchaAction2, clientContext)
                if (error) {
                    toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
                    setIsLoading(false)
                    return
                }
                canSubmitForm = status === 200 || status === 201
            } else {
                canSubmitForm = true
            }
            if (canSubmitForm) {
                //- ideme na server s form datami
                const {payload, error, status} = await request(resetPasswordRequestAction, clientContext)
                if (error) {
                    toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                    setIsLoading(false)
                    return
                }
                if (status === 200 || status === 201) {
                    setIsEmailSent(true) // aj ak server neodoslal email, tak sa tvarime ze email bol odoslaný
                    toast.success(EMAIL_SENT_MESSAGE)
                }
            } else {
                toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
            }
        } else {
            toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    return (
        <Container sx={{mt: '4%', mb: '3%'}}>
            <Box display='flex' justifyContent='center' alignItems='center'>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px'}}>
                        <div className="w-100 text-center">
                            <Typography variant='h6' component='div' style={{fontFamily: 'OpenSansBold'}}
                                        className='text-primary'>
                                Požiadavka na zmenu hesla
                            </Typography>
                        </div>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className="form-group">
                                        <label htmlFor="email" className='required text-primary' style={{fontFamily: 'OpenSansBold'}}>
                                            Email
                                        </label>
                                        <input type="email" name='email' id='email' className='form-control border-primary rounded-borders'
                                               value={email ?? ''}
                                               onChange={onChange}/>
                                        <div>
                                            {isEmailValid && errorMessage !== null ? null :
                                                <span className='error'>
                                                    {errorMessage}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className="form-group w-100 text-center">
                                        <button
                                            type="submit"
                                            className="background-primary rounded-borders border-primary"
                                            style={{fontFamily: 'OpenSansBold', fontSize: '18px', padding: '10px'}}
                                        >
                                            Odoslať
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    )
}

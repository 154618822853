import * as React from 'react'
import { Button, Modal, ModalDialog } from 'react-bootstrap'
import { FaCheck, FaTimes } from "react-icons/fa"

interface ConfirmModalProps {
    show: boolean,
    onCloseCallback: Function,
    onSuccessCallback: Function,
    title: string,
    message?: string | null,
}

export default function ConfirmModal(props: ConfirmModalProps): JSX.Element {
    const [confirmed, setConfirmed] = React.useState<boolean>(false)
    const [isConfirmed, setIsConfirmed] = React.useState<boolean>(true)
    const messageDefault = 'Naozaj chcete pokračovať?'
    const handleSuccess = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (confirmed) {
            //- zavolame callback z parent komponentu
            props.onSuccessCallback()
            // vyresetujeme state
            setConfirmed(false)
            setIsConfirmed(true)
        } else {
            setIsConfirmed(false)
        }
    }

    return (
        <ModalDialog>
            <Modal show={props.show}
                   centered={true}
                   backdrop="static"
                   keyboard={false}
                   size='lg'
                   onHide={() => props.onCloseCallback()}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>
                        Potvrdenie
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {props.message ? props.message : messageDefault}
                    </p>
                    <div className="form-group">
                        <input type="checkbox" name='confirm' id='confirm' checked={confirmed}
                               onChange={() => setConfirmed(!confirmed)}/>
                        <label className="form-check-label" htmlFor='confirm'>
                            Potvrdzujem, že som si vedomý, že akciu už nebude možné vrátiť späť.
                        </label>
                        <div>
                            {isConfirmed ? null : (
                                <div className="alert alert-danger">
                                    Musíte potvrdiť akciu
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='text-center mt-1'>
                        <Button variant="danger" onClick={() => props.onCloseCallback()} style={{marginRight: '10px'}}>
                            <FaTimes/>
                            Storno
                        </Button>
                        <Button variant="success" onClick={handleSuccess}>
                            <FaCheck/>
                            Potvrdiť
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </ModalDialog>
    )
}

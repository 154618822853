import {Action} from "react-fetching-library"

interface RequestResponse {
    error: boolean,
    payload: any,
    status: number,
}

export const getApiUrlFromRelativeUrl = (relativeUrl: string): string => {
    const baseUrl: string | undefined = process.env.REACT_APP_BACKEND_HOST
    const env: "development" | "production" | "test" = process.env.NODE_ENV
    // ak je production build tak chceme ist priamo na backend, ak je dev tak ideme cez proxy
    if (env === "production") {
        return `${baseUrl}${relativeUrl}`
    } else {
        return relativeUrl
    }
}

export const request = async (action: Action, clientContext: any): Promise<RequestResponse> => {
    const response = await clientContext.query(action)

    return {
        error: response.error,
        payload: response.payload,
        status: response.status,
    }
}

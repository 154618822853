import * as React from 'react'
import { Container, Typography } from "@mui/material"
import { AuthContext } from "../../App"
import OrderInterface from "../../types/Order/OrderInterface"
import { Action, ClientContext } from "react-fetching-library"
import { useNavigate, useParams } from "react-router-dom"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import Loading from "../../components/loader/Loading"
import ErrorComponent from "../../components/error/ErrorComponent"
import CheckIcon from "@mui/icons-material/Check"
import { dividePriceString, validatePriceString } from "../../utils/priceObject/PriceObjectUtil"
import { toast } from "react-toastify"

export default function CreatePaymentByOrderPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const COULDNT_CREATE_PAYMENT: string = 'Nepodarilo sa vytvoriť platbu'
    const PAYMENT_CREATED_SUCCESSFULLY: string = 'Platba bola úspešne vytvorená a URL bola skopírovaná do schránky'
    // state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isError, setIsError] = React.useState<boolean>(false)
    const [order, setOrder] = React.useState<OrderInterface | null>(null)
    const [price, setPrice] = React.useState<string | null>(null)
    const [priceErrors, setPriceErrors] = React.useState<string[]>([])
    // init navigate
    const navigate = useNavigate()
    const params = useParams()
    const {orderId} = params
    // context
    const authContext = React.useContext(AuthContext)
    const clientContext = React.useContext(ClientContext)
    //- actions
    const getOrderAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/order/get/' + orderId),
        headers: {
            Accept: 'application/json'
        },
    }
    const submitPriceAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/order/createPayment'),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json'
        }
    }
    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    //- effect - right
    React.useEffect(() => {
        if (authContext.userLogged === null) {
            navigate('/login')
        }
        //- right - user musi mat 'canSetFeatureXUserXAdvertisement=true'
        if (!authContext.userLogged?.hasAccessToProOrders) {
            navigate('/orders-pro')
        }
    }, [authContext.userLogged])

    //- effect - get order
    React.useEffect(() => {
        const getOrder = async () => {
            const {error, payload, status} = await request(getOrderAction, clientContext)
            if (error) {
                setIsLoading(false)
                setIsError(true)
                return
            }
            if (status === 200) {
                setOrder(payload)
            }
            setIsLoading(false)
        }
        getOrder()
    }, [])

    // submit
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (validatePriceString(price) !== true) {
            const error = `${validatePriceString(price)}`
            setPriceErrors([error])
            toast.error(COULDNT_CREATE_PAYMENT)
            return
        }
        const priceObject = dividePriceString(price)
        const action2 = {
            ...submitPriceAction,
            body: {
                price: priceObject.price,
                orderId: order!.id
            }
        }
        setIsLoading(true)
        const {error, status, payload} = await request(action2, clientContext)
        // handle error
        if (error && status === 500) {
            setIsError(true)
        }
        if (status === 401) {
            //- ak prisla odpoved zo servera, ze user nie je autorizovany, tak ho odhlasime
            authContext.logout()
        }
        if (status === 200 || status === 201) {
            let paymentId = payload.data.uniqueId
            let url: string = window.location.protocol + '//' + window.location.hostname + '/createPayment/' + paymentId
            // copy to clipboard
            navigator.clipboard.writeText(url)
            toast.success(PAYMENT_CREATED_SUCCESSFULLY)
            navigate('/orders-pro')
        } else {
            toast.error(COULDNT_CREATE_PAYMENT)
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <Container sx={{mt: '3%', mb: '2%'}}>
            <Typography className='text-primary' variant='h6' style={{fontFamily: 'OpenSansBold'}}>
                Vytvoriť platbu pre objednávku s číslom {order?.number}
            </Typography>
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label htmlFor="startingPrice" style={{fontFamily: 'OpenSansBold'}}
                           className='text-primary required'>
                        Cena platby
                    </label>
                    <input type="text" name="startingPrice" id="startingPrice"
                           className={`${priceErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                           value={price ?? ''}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrice(e.target.value)}
                    />
                    <div>
                        <div>
                            {priceErrors.map((error: string, index: number) => (
                                <p key={index} className='error'>
                                    {error}
                                </p>
                            ))}
                        </div>
                        <div>
                            <small>
                                Cenu zadavajte bez medzery a bez meny
                            </small>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-2">
                    <button className="btn btn-success">
                        <CheckIcon/>
                        Vytvoriť platbu
                    </button>
                </div>
            </form>
        </Container>
    )
}
